@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
*{
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth;
}
/* *:not(i):not(span) {
    font-family: 'Merriweather', serif !important;
} */

*:not(i):not(span) {
  font-family: "Mulish", sans-serif !important;
}

.App {
  text-align: center;
}


/* h1{
  font-size: 2.5rem;
  font-weight: 700;
}

p{
  font-size: 18px;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bookingTabActiveState {
  box-sizing: border-box;
  border-bottom: 5px solid #007bff;
  border-radius: 5px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 7px;
  background-color: transparent, rgba(0, 0, 0, 0.1);
  opacity: 0;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #4e00c2; */
  background: #007bff;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6600ff;
}

html {
  overflow: overlay;
}

body {
  margin: 0;
  padding: 0;
  letter-spacing: -0.01em;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-family: "Manrope", sans-serif !important;
  }
  .image-section_tc{ 
    margin: 0px;
   width: 150px;
  }
  .image-section_tc img{
    width: 100px;
    height: auto;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
  
  .title_tc{
      font-size: 25px;
      text-align: left;
      padding-left: 50px;
  }
  .content_tc h3{
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    padding: 0px 50px 0px;
  }
  .content_tc p{
    font-size: 15px;
    font-weight: lighter;
    text-align: left;
    padding: 0px 50px 0px;
  } 
  .content-section_tc .social{
      margin: 40px 40px;
  }
  .content-section_tc .social i{
    color: #1E90FF;
    font-size: 30px;
    padding: 0px 10px;
}
.booknow_component_css {
  box-shadow: 6px 10px 10px 5px #888888;
}
.img_booknow {
  background: url(/static/media/bookashot.65f430a6.png) center no-repeat;
  background-size: cover;
  height: 80vh;
}

.form-container {
  display: flex;
  justify-content: center;
  min-height: 70vh;
}

.bookNowcomponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  min-width: 60vh;
}

.bookNowcomponent h4 {
  font-weight: bold;
  color: #fff;
}

.bookNowcomponent .form-input {
  position: relative;
}

.bookNowcomponent .form-input input {
  width: 40vh;
  height: 40px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  outline: none;
  background: white;
  padding-left: 45px;
}

.bookNowcomponent .form-input span {
  position: absolute;
  top: 8px;
  padding-left: 20px;
  color: #777;
}

.bookNowcomponent .form-input input::-webkit-input-placeholder {
  padding-left: 0px;
}

.bookNowcomponent .form-input input::placeholder {
  padding-left: 0px;
}

.bookNowcomponent .form-input input:focus,
.bookNowcomponent .form-input input:valid {
  border-bottom: 2px solid #48403d;
}

.next {
  border: none;
  cursor: pointer;
  width: 150px;
  height: 40px;
  margin-left: 65px;
  background-color: #87ceeb;
  color: #000;
  font-weight: bold;
  border-radius: 20px;
}

body {
  margin: 0;
  padding: 0;
  letter-spacing: -0.01em;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-family: "Manrope", sans-serif !important;
  }
  .image-section_ppolicy{ 
    margin: 0px;
   width: 150px;
  }
  .image-section_ppolicy img{
    width: 100px;
    height: auto;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
  
  .title_ppolicy{
      font-size: 25px;
      text-align: left;
      padding-left: 50px;
  }
  .content_ppolicy h3{
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    padding: 0px 50px 0px;
  }
  .content_ppolicy p{
    font-size: 15px;
    font-weight: lighter;
    text-align: left;
    padding: 0px 50px 0px;
  } 
  .content-section_ppolicy .social{
      margin: 40px 40px;
  }
  .content-section_ppolicy .social i{
    color: #1E90FF;
    font-size: 30px;
    padding: 0px 10px;
}
/* @import url("http://fonts.googleapis.com/css?fam"); */
.image-section {
  margin: 0px;
  width: 150px;
}

.image-section img {
  width: 100px;
  height: auto;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.title {
  font-size: 25px;
  text-align: left;
  padding: 10px 0;
}

.cancPolicyContent h3 {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  /* padding: 0px 50px 0px; */
}

.cancPolicyContent p {
  font-size: 15px;
  font-weight: lighter;
  text-align: left;
}

.content-section .social {
  margin: 40px 40px;
}

.content-section .social i {
  color: #1e90ff;
  font-size: 30px;
  padding: 0px 10px;
}

.label_login {
  margin-left: 58px;
  font-size: 18px;
  display: table;
  margin-bottom: 10px;
}
.btn-group,
.btn-group-vertical {
  display: grid;
  position: relative;
  vertical-align: middle;
  width: 76%;
  margin-inline: auto; 
  border: 1px solid #ddd;
  grid-template-columns: 1fr 1fr;
}

.login-box .btn-group{
  display: grid;
}
.btn-group .btn {
  /* flex: 1 1 auto; */
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.btn--preferred {
  background-color: transparent;

  color: #007bff;
  transition: border 0.7s ease, color 0.7s ease, background 0.7s ease;
  font-size: 1.4rem;
}

.privacy_text {
  font-size: 13px;
  padding-bottom: 8px;
  width: 78%;
  margin: auto;
}
.foot-lnk {
  display: block;
  width: 102%;
}
.image_login {
  align-items: center;
  width: 18%;
  height: -webkit-min-content;
  height: min-content;
  /* margin-left: 190px; */
  margin: 0 auto;
  display: block;
}
.login-box {
  position: relative;
  margin: 10px auto;
  width: 500px;
  /* height: 716px; */
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.33);
}

.email-login,
.email-signup {
  position: relative;
  /* float: left; */
  width: 100%;
  height: auto;
  margin-top: 20px;
  text-align: center;
}
.u-form-group {
  width: 163%;
  margin-bottom: 11px;
  align-items: center;
  margin-left: -150px;
}
.u-form-group input[type="email"],
.u-form-group input[type="text"],
.u-form-group input[type="password"] {
  width: calc(50% - 22px);
  height: 45px;
  outline: none;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 2px;
  color: #333;
  font-size: 0.8rem;
  transition: all 0.1s linear;
}
.u-form-group input:focus {
  border-color: #358efb;
}
.u-form-group button {
  width: 47%;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding: 13px 0;
  border-radius: 2px;
  text-transform: uppercase;
  background: #007bff;
}
.u-form-group button:hover{
  background: #005bbc;
}
.forgot-password {
  width: 50%;
  text-align: left;
  text-decoration: underline;
  color: #888;
  font-size: 0.75rem;
}

@media (max-width: 770px) {
  /* .login-box{
    width: 102vw;
  } */

  .u-form-group {
    width: 100%;
    margin-left: 0;
  }

  .u-form-group input[type="email"],
  .u-form-group input[type="text"],
  .u-form-group input[type="password"] {
    /* width: 55vw; */
    width: 88%;
  }

  .label_login {
    /* margin-left: 0;
    display: inline-block; */
    display: flex;
    margin-left: 6%;
  }
}

@media (max-width: 500px) {
  .login-box {
    width: 95%;
  }

  /* .image_login {
    position: relative;
    left: 44%;
    margin-left: 0;
  } */

  .privacy_text{
    margin-left: 0;
    width: 100%;
  }
}

.pLogin_header{
  background: #ffffff;
  color: #007bff;
  text-align: center;
  width: 80%;
  margin: 0 auto 10px;
  padding: 0.4rem 1rem;
  border-bottom: 2px solid #007bff;
}

.pLogin_header h3{
  margin: 0;
}

.u-form-group button {
  width: auto;
  padding: 0.5rem 3.5rem;
}

@media (max-width: 770px){
  .pLogin_header > h3{
    font-size: 21px;
  }
}
.p_register-container {
    position: relative;
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
}

.pResister_header {
    background: #ffffff;
    color: #007bff;
    text-align: center;
    width: 80%;
    margin: 0 auto 10px;
    padding: 0.4rem 1rem;
    border-bottom: 2px solid #007bff;
}

.pResister_header h3 {
    margin: 0;
}

.p_register-container .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 25px;
    cursor: pointer;
}

.pRegister_form {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.p-form-group button {
    width: auto;
    padding: 0.5rem 3.5rem;
    border: none;
    outline: none;
    color: white;
    font-size: 14px;
    font-weight: normal;
    border-radius: 2px;
    text-transform: uppercase;
    background: #007bff;
}

.p-form-group button:hover {
    background: #005bbc;
}

@media (max-width: 770px) {
    .pResister_header>h3 {
        font-size: 21px;
    }
}
.photoshoot_home {
  /* width: 90%; */
  margin: 70px auto;
  /* margin-top: -45px; */
}
.heading_photoshoot {
  font-size: 2.5em;
  font-weight: 700 ;
  margin-bottom: 1.4rem;
}
.photoshoot_home .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.photoshoot_home .explore-photoshoot {
  padding: 5px 16px;
  background-color: skyblue;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

.photoshoot_home .grid {
  display: grid;
  grid-gap: 4em;
  grid-template-columns: 1fr 1fr 1fr;
}

.photoshoot_home .grid .grid-items {
  padding: 1em;
  box-shadow: 2px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
}

.photoshoot_home .grid .grid-items img {
  width: 100%;
  margin-bottom: 1em;
  border-radius: 0.5em 0.5em 0 0;
}

.photoshoot_home .grid .grid-items p {
  font-size: 20px;
}

.explore_buttonn {
  width: 100%;
  margin-top: 1em;
  text-align: center;
}
#explore_home_button {
  padding: 0.6em 2em;
}

.img-description {
  padding: 0 0.5em;
}

.img-description p {
  color: #000;
  margin-top: 1em;
}

@media screen and (max-width: 920px) {
  .explore_buttonn {
    padding-top: 17px;
    /* margin-left: -281px;
    width: 164%; */
    /* padding-top: 2px; */
    padding-bottom: 37px;
  }

  #explore_home_button {
    /* margin-left: 50%; */
    /* margin-top: -88px; */
    width: 50%;
    padding: 14px;
    display: block;
    margin: auto;
    margin-bottom: 28px;
  }

  .photoshoot_home .grid {
    /* display: grid;
    grid-gap: 0em; */

    display: flex;
    flex-direction: column;
    text-align: center;
    /* width: 85vw; */
  }

  .photoshoot_home .grid .grid-items img {
    width: 60vw;
  }
}

@media only screen and (max-width: 768px) {
  .explore_buttonn {
    /* padding-top: 52px; */
    /* margin-left: -281px;
    width: 164%; */
    /* padding-top: 2px; */
    padding-bottom: 37px;
  }
  /* .photoshoot_home .grid {
 

    display: flex;
    flex-direction: column;
    text-align: center;
  } */
  .photoshoot_home {
    /* width: 90%; */
    margin: 30px auto;
  }

  @media screen and (max-width: 524px) and (min-width: 320px) {
    .photoshoot_home {
      /* margin-top: -170px; */
    }
    .heading_photoshoot {
      font-size: 22px;
      font-weight: 600;
    }

    .photoshoot_home .grid {
      /* display: block; */
      grid-gap: 2em;
      /* justify-content: center; */
      /* margin-left: -10px; */
      /* margin-right: -38px; */
    }

    .photoshoot_home .grid .grid-items img {
      width: 60vw;
    }

    .img-description h3 {
      font-size: 18px;
      font-weight: 700;
    }
    .photoshoot_home .grid .grid-items p {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 518px) {
  #explore_home_button {
    width: 76%;
  }
  .heading_photoshootservices {
    /* margin-top: 190px !important; */
    font-size: 26px;
    font-weight: 600;

  }
}

#CallbackPopup_popupContainer__j9nod {
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
}

#CallbackPopup_popupCallbackContainer__qNDB_ {
  width: 95%;
  /* height: 80vh; */
  /* margin: 1em auto; */
  margin: 5em auto;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  /* background-color: #eff3fa;
  border-radius: 1.5em;
  padding: 3em;*/
}

#CallbackPopup_popupCallbackContainer__inner__J0twW {
  width: 50vw;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eff3fa;
  border-radius: 1.5em;
  padding: 3em;
  /* position: fixed; */
  /* top: 6em; */
  overflow: hidden;
}

#CallbackPopup_popupCallbackContainer__qNDB_ #CallbackPopup_closeIcon__AQ66w {
  display: none;
}

#CallbackPopup_popupContainer__inner__1atkh {
  width: 50vw;
  height: 85vh;
  /* margin: 1em auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: #eff3fa;
  border-radius: 1.5em;
  padding: 2em;
  position: fixed;
  top: 4em;
  overflow: hidden;
}

#CallbackPopup_popupHeader__20GYD h1 {
  width: auto;
  text-align: center;
  font-size: 2.5rem;
  /* font-weight: 700; */
}

#CallbackPopup_closeIcon__AQ66w {
  color: rgb(139, 137, 137);
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 24px;
}

#CallbackPopup_popupForm__inputContainer__3F0mB {
  display: flex;
  width: 50vw;
  /* height: 30vh; */
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 1em;
}

.CallbackPopup_popupForm__input__7obOI {
  display: flex;
  flex-direction: column;
  
  
  /* margin-bottom: 0.5em; */
}

.CallbackPopup_popup__TextField__NOKa3 {
  width: 20vw;
  padding: 0.5em 1em;
  /* margin: 8px 0; */
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.CallbackPopup_popupForm__input__7obOI label {
  font-size: 14px;
  color: #000;
  margin: 0;
}

#CallbackPopup_popup-photoshootDetails__container__18FlU {
  width: 43vw;
  /* height: 60vh; */
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

#CallbackPopup_popup-photoshootType__container__xsoEC {
  width: 23vw;
}

#CallbackPopup_photoshootType__heading__3cnzb label {
  font-size: 16px;
  color: #000;
  /* margin: 0.5em 0; */
  width: 100%;
}

.CallbackPopup_popup-photoshootType__3gxMW {
  width: 30vw;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
}

.CallbackPopup_popup-photoshootType__Section1__q4Lwg {
  width: 40%;
}
.CallbackPopup_popup-photoshootType__Section2__189fB {
  width: 50%;
}

.CallbackPopup_popup-photoshootType__3gxMW input {
  margin-right: 0.5em;
}

.CallbackPopup_popup-photoshootType__3gxMW label {
  margin-bottom: 0.1em;
  font-weight: bold;
}

#CallbackPopup_popup-button__25r9H {
  margin: 2em auto;
  /* width: 25em; */
  padding: 0.8em 6em;
  border-radius: 5em;
  border: none;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
}

#CallbackPopup_popup-button__25r9H:hover {
  background-color: #0069d9;
}

#CallbackPopup_photoshoot-city__container__2BFIV {
  width: 20vw;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
}

#CallbackPopup_photoshoot-city__itOav {
  padding: 0.5em 1em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 15px;
}

#CallbackPopup_popup__requestField__3J1Ph {
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 15px;
  resize: none;
}

@media (max-width: 1500px) {
  #CallbackPopup_popupContainer__inner__1atkh,
  #CallbackPopup_popupCallbackContainer__inner__J0twW {
    width: 60%;
  }
  .CallbackPopup_popup-photoshootType__3gxMW {
    width: 25vw;
    font-size: 14px;
  }
  #CallbackPopup_popupCallbackContainer__qNDB_ iframe {
    width: 550px;
    height: 320px;
    margin-right: 1em;
  }
}
/* @media (max-width: 1200px) {
  #popupContainer__inner {
    width: 70%;
  }
} */

@media (max-width: 1000px) {
  #CallbackPopup_popupCallbackContainer__qNDB_ {
    height: auto;
    flex-direction: column;
  }
  #CallbackPopup_popupCallbackContainer__qNDB_ iframe {
    margin-bottom: 1em;
    margin-right: 0;
  }
  #CallbackPopup_popupContainer__inner__1atkh,
  #CallbackPopup_popupCallbackContainer__inner__J0twW {
    width: 65vw;
  }
  #CallbackPopup_popup-photoshootDetails__container__18FlU {
    width: 90%;
  }
  .CallbackPopup_popup-photoshootType__3gxMW {
    font-size: 14px;
  }
  /* #popupForm__inputContainer {
    width: 100%;
    margin-top: 0.5em;
  }
  .popup__TextField {
    width: 18vw;
    padding: 0.2em 1em;
  } */
  
#CallbackPopup_popupContainer__inner__1atkh {
  height: auto;
  top: auto;
}
}

@media (max-width: 910px) {
  #CallbackPopup_popupContainer__inner__1atkh,
  #CallbackPopup_popupCallbackContainer__inner__J0twW {
    width: 70vw;
    /* height: 75vh; */
  }
  #CallbackPopup_popupForm__inputContainer__3F0mB {
    width: 60vw;
  }
  .CallbackPopup_popup__TextField__NOKa3 {
    width: 25vw;
  }
  .CallbackPopup_popup-photoshootType__3gxMW {
    width: 32vw;
  }
  #CallbackPopup_photoshoot-city__container__2BFIV {
    width: 25vw;
  }
}

@media (max-width: 820px) {
  #CallbackPopup_popupContainer__inner__1atkh,
  #CallbackPopup_popupCallbackContainer__inner__J0twW {
    width: 80vw;
  }
  #CallbackPopup_popupForm__inputContainer__3F0mB {
    width: 70vw;
  }
  .CallbackPopup_popup__TextField__NOKa3 {
    width: 30vw;
  }
  .CallbackPopup_popup-photoshootType__3gxMW {
    width: 32vw;
  }
  #CallbackPopup_photoshoot-city__container__2BFIV {
    width: 30vw;
  }
}

@media (max-width: 765px) {
  .CallbackPopup_popup__TextField__NOKa3 {
    padding: 0.2em 1em;
  }
  .CallbackPopup_popup-photoshootType__3gxMW {
    max-height: 20vh;
    overflow-y: scroll;
    display: block;
    width: 25vw;
  }
  ::-webkit-scrollbar-thumb{
    color: red;
  }
  .CallbackPopup_popup-photoshootType__Section1__q4Lwg,
  .CallbackPopup_popup-photoshootType__Section2__189fB {
    width: 100%;
  }
  #CallbackPopup_popupHeader__20GYD {
    width: 70vw;
  }
  #CallbackPopup_popupHeader__20GYD h1 {
    width: 65vw;
    font-size: 24px;
  }
  #CallbackPopup_popup-button__25r9H {
    padding: 0.6em 5em;
  }
  /* #popupCallbackContainer__inner #popupHeader h1 {
    margin-top: 4em;
  } */
}

@media (max-width: 600px) {
  #CallbackPopup_popupCallbackContainer__qNDB_ iframe {
    width: 400px;
    height: 280px;
  }
  #CallbackPopup_popupCallbackContainer__qNDB_ #CallbackPopup_popupContainer__inner__1atkh,
  #CallbackPopup_popupCallbackContainer__inner__J0twW {
    width: 90vw;
  }
  /* #popup-photoshootType__container {
    width: 60vw;
  } */
  #CallbackPopup_popupContainer__inner__1atkh {
    height: 80vh;
    top: 4em;
  }

  .CallbackPopup_popup-photoshootType__3gxMW {
    max-height: 20vh;
    overflow-y: scroll;
    display: block;
    /* width: 0vw; */
  }
  .CallbackPopup_popup-photoshootType__Section1__q4Lwg {
    width: 100%;
  }
  .CallbackPopup_popup-photoshootType__Section2__189fB {
    width: 100%;
  }
  #CallbackPopup_photoshootType__heading__3cnzb label {
    width: 40vw;
  }
  #CallbackPopup_popup-button__25r9H {
    padding: 0.4em 4em;
  }
  #CallbackPopup_popup-photoshootType__container__xsoEC {
    margin-bottom: 1em;
  }
}

@media (max-width: 450px) {
  #CallbackPopup_popupCallbackContainer__qNDB_ iframe {
    width: 380px;
    height: 280px;
  }
  #CallbackPopup_popupContainer__inner__1atkh,
  #CallbackPopup_popupCallbackContainer__inner__J0twW {
    width: 95vw;
    /* max-height: 80vh; */
    overflow-y: scroll;
  }
  #CallbackPopup_popupHeader__20GYD {
    width: 80vw;
  }
  #CallbackPopup_popupHeader__20GYD h1 {
    width: 75vw;
    font-size: 21px;
  }
  #CallbackPopup_popupForm__inputContainer__3F0mB {
    width: 80vw;
  }
  .CallbackPopup_popup__TextField__NOKa3 {
    width: 38vw;
  }

  #CallbackPopup_popup-photoshootDetails__container__18FlU {
    display: block;
    margin-top: 0.6em;
    /* padding: -1em; */
  }
  #CallbackPopup_popup-photoshootType__container__xsoEC {
    width: 70vw;
  }
  .CallbackPopup_popup-photoshootType__3gxMW {
    max-height: 20vh;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .CallbackPopup_popup-photoshootType__Section1__q4Lwg {
    width: 40%;
  }
  .CallbackPopup_popup-photoshootType__Section2__189fB {
    width: 50%;
  }
  #CallbackPopup_photoshoot-city__container__2BFIV {
    width: auto;
  }
}

@media (max-width: 380px) {
  #CallbackPopup_popupCallbackContainer__qNDB_ iframe {
    width: 310px;
    height: 185px;
  }
  #CallbackPopup_popupHeader__20GYD h1{
    font-size: 21px;
  }
  #CallbackPopup_closeIcon__AQ66w {
    top: 1rem;
    right: 1rem;
  }
  #CallbackPopup_popup-button__25r9H {
    padding: 0.4em 3em;
  }
  #CallbackPopup_popup-photoshootDetails__container__18FlU {
    width: 110%;
  }
  #CallbackPopup_popup-photoshootType__container__xsoEC {
    width: 100vw;
    margin-bottom: 1em;
  }
  #CallbackPopup_photoshootType__heading__3cnzb label {
    width: 45vw;
  }
  #CallbackPopup_photoshoot-city__container__2BFIV {
    width: auto;
  }
}

.CallbackAlert_alertContainer__uuSV1 {
  width: 99%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 505;
  transition: 2s ease-in;
}

.CallbackAlert_alertContainer__inner__1qXEE {
  width: 50%;
  height: auto;
  background-color: #007bff;
  color: #fff;
  border-radius: 1.5em;
  padding: 1.5em;
  position: fixed;
  top: 6em;
  overflow: hidden;
}

.CallbackAlert_alertContainer__uuSV1 .CallbackAlert_alertContainer__inner__1qXEE p {
  margin-bottom: 0;
}

.modal_bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
}

.book_package_modal .close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 25px;
  cursor: pointer;
}

.modal-heading-container {
  background-color: #007bff;
  text-align: center;
  padding: 0.5rem;
  width: calc(100% - 44px);
  margin: auto;
}

.modal-heading {
  font-size: 22px;
  margin: 0;
  color: #fff;
}

.book_package-form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem auto;
  width: calc(100% - 44px);
}

.input-group {
  display: flex;
}

.input-group > input,
.input-group > select {
  flex: 1 1;
  padding: 4px;
}

.input-group > input:nth-child(2),
.input-group > select:nth-child(2) {
  margin-left: 20px;
}

.book_package-submit_btn {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 32px;
  border-radius: 6px;
  background: #007bff;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: auto;
}
.book_package-submit_btn:hover {
  background: #005bbc;
  color: #fff;
}
@media (max-width: 770px) {
  /* .input-group {
    flex-direction: column;
  } */

  /* .input-group > input:nth-child(2),
  .input-group > select:nth-child(2) {
    margin: 0px;
  } */
}

@media (max-width: 500px) {
  .book_package_modal{
    height: 80vh;
    overflow: scroll;
  }
  .input-group {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .input-group > input:nth-child(2),
  .input-group > select:nth-child(2) {
    margin: 0px;
  }
}

/* AddCityPage.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  border: 2px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.addcity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
} 

label {
  margin-bottom: 12px;
}
.addcity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}



input[type="text"],
textarea {
  /* width: 100%; */
  padding: 6px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing of the textarea */
  text-align: left; /* Align text to the left */
}


button {
  padding: 8px 16px;
  margin-bottom: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="button"] {
  /* background-color: #dc3545; */
  /* display:inline-flex; */
  /* margin: 5px; */
}

.spot-container {
  display: flex;
  align-items: center;
}

.spot-input {
  width: 100%;
  margin-bottom: 8px;
}

.spot-remove-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 8px;
  cursor: pointer;
}

/* Rest of the styles... */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --main-bg-color: #65dfc9;
  --secondary-bg-color: #6cdbeb;
}

#dashboard-content {
  padding: 10px;
  width: 95%;
  margin-left: 5%;
  display: block;
  position: relative;
  top: 18%;
  z-index: 1;
}

#dashboard-content_profile_user {
  /* width: 82%; */
  padding: 10px;
  /* margin-left: 9%; */
}

#booking-user-history {
  padding: 10px;
  width: 100%;
  /* margin-left: 15%; */
  display: flex;
  justify-content: space-between;
}

#logout-button {
  float: right;
  position: absolute;
  top: 30px;
  right: 30px;
}

@media screen and (max-width: 750px) {
  #logout-button {
    top: 90px;
  }
}

#booking-user-history_img {
  width: 40%;
  height: 320px;
  /* background: rgb(114, 114, 114); */
  outline: none;
  border: none;
}

#booking-user-history_text {
  width: 50%;
  height: 320px;
  /* background: rgb(114, 114, 114); */
  outline: none;
  border: none;
  text-align: center;
  box-shadow: 0 6px 12px -8px rgb(44 44 63 / 12%),
    0 9px 24px 0 rgb(44 44 63 / 8%), 0 12px 32px 16px rgb(44 44 63 / 5%);
}

#text_container {
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: -30%;
}

#booking-user-history_img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 6px 12px -8px rgb(44 44 63 / 12%),
    0 9px 24px 0 rgb(44 44 63 / 8%), 0 12px 32px 16px rgb(44 44 63 / 5%);
}

@media screen and (max-width: 650px) {
  #booking-user-history {
    flex-direction: column;
    margin-bottom: 5%;
  }
  #booking-user-history_img {
    width: 100%;
    margin-bottom: 5%;
  }
  #booking-user-history_text {
    width: 100%;
  }
}

#dashboard-left {
  position: relative;
  top: 18%;
  z-index: 10;
  width: 2%;
  text-align: center;
  transition: all ease 0.5s;
  color: #fff;
  font-size: 20px;
  background: #122143;
  /* background: #007bff; */
  border-radius: 3px;
  justify-content: center;
  height: 80%;
  position: fixed;
  overflow: hidden;
}

@media screen and (max-width: 750px) {
  #dashboard-left {
    width: 5%;
  }
}

#dashboard-left_nav {
  text-align: center;
  transition: all ease;
  color: #fff;
  font-size: 20px;
  /* background: #122143; */
  /* background: #007bff; */
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  justify-content: center;
  /* height: 60vh; */
  padding: 10px;
  width: 80%;
  margin-left: 10%;
  height: 100%;
  visibility: hidden;
}

#dashboard-left_nav a {
  text-decoration: none;
  padding: 28px 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #0d172e;
  color: #ffffff;
  display: block;
  font-size: 17px;
  font-weight: 500;
}

#dashboard-left_nav a:first-child {
  margin-top: 5%;
}

#dashboard-left_nav a:hover {
  border-left: 5px solid #5584ff;
}

#icon-left {
  position: absolute;
  top: 50%;
  right: 0px;
  font-size: 30px;
  color: #007bff;
  cursor: pointer;
  visibility: hidden;
}

#icon-right {
  position: absolute;
  top: 50%;
  right: 0px;
  font-size: 30px;
  color: #007bff;
  cursor: pointer;
}

#booking {
  min-height: 100vh;
  /* border: none;
    border-radius: 7px;
    box-shadow: -1px 0px 11px 5px rgba(112, 84, 84, 0.35);
    -webkit-box-shadow: -1px 0px 11px 5px rgba(112, 84, 84, 0.35);
    -moz-box-shadow: -1px 0px 11px 5px rgba(112, 84, 84, 0.35); */
}

#booking-btn {
  /* width: 30%;
    margin-left: 35%;
    position: relative;
    bottom: -40%; */
  border-radius: 7px;
}

/* 
@media screen and (max-width: 750px) {
    #booking-btn {
        width: 50%;
        margin-left: 25%;
        position: relative;
        bottom: -15%;
    }
} */

#Dashboard-Route {
  min-height: 100vh;
  margin-bottom: 7%;
}

#myphotos {
  /* margin-top: 7%; */
  height: 100vh;
  /* border: none;
    border-radius: 7px;
    box-shadow: -1px 0px 11px 5px rgba(112, 84, 84, 0.35);
    -webkit-box-shadow: -1px 0px 11px 5px rgba(112, 84, 84, 0.35);
    -moz-box-shadow: -1px 0px 11px 5px rgba(112, 84, 84, 0.35); */
}

#bookings {
  min-height: 100vh;
  margin-bottom: 7%;
}

#payment_head {
  text-align: center;
  padding: 10px;
}

#myshoot {
  min-height: 80vh;
  margin-bottom: 7%;
  /* margin-top: 7%; */
}

#pendingrequest {
  min-height: 100vh;
}

#payment-head {
  text-align: center;
  padding: 10px;
}

/* 
#leftnav-animation {
    transform: rotate(270deg);
    position: absolute;
    left: -3%;
    top: 50%;
    letter-spacing: 3px;
} */

/* 
#dashboard-container {
    padding: 10px;
    /* display: grid;
    grid-template-columns: 20% 80%; */

/* display: flex; */

/* justify-content: space-between; */

/* }  */

/* 
#dashboard-left {
    width: 2%;
    overflow: hidden;
    float: left;
} */

/* @media screen and (max-width: 750px) {
    /* #dashboard-container {
        /* display: flex;
        width: 100%; */

/* grid-template-columns: 30% 70%; */

/* display: block; */

/* } */

/* } */

/* 
@media screen and (max-width: 360px) {
    #dashboard-container {
        /* display: flex;
        width: 100%; */

/* grid-template-columns: 40% 60%; */

/* display: block; */

/* } */

/* } */

/* 
@media screen and (max-width: 411px) {
    #dashboard-container {
        /* display: flex;
        width: 100%; */

/* grid-template-columns: 45% 55%; */

/* display: block; */

/* } */

/* } */

/* 
#dashboard-left {
    height: 80vh;
    width: 80%;
    position: relative;
    top: 2vh;
    z-index: 100000;
} */

/* 
#dashboard-left_nav {
    text-align: center;
    transition: all ease;
    color: #fff;
    font-size: 20px;
    background: #122143;
    background: #007bff;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 3px;
    justify-content: center;
} */

/* 
#dashboard-left_nav a {
    text-decoration: none;
    padding: 28px 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #0d172e;
    color: #ffffff;
    display: block;
    font-size: 17px;
    font-weight: 500;
}

#dashboardbutton {
    display: none;
}

@media screen and (max-width: 750px) {
    #dashboard-left_nav {
        /* visibility: hidden; */

/* z-index: 100000;
        width: 0px; */

/* } */

/* #dashboardbutton {
        display: block;
    } */

/* }
#dashboard-left_nav a:hover {
    border-left: 5px solid #5584ff;
}
#dashboard-content {
    width: 100%;
} */

/* 
#dashboard-content_profile {
    display: block;
    height: 100px;
    border-bottom: 1px solid #545b5c;
    overflow: hidden;
}

#dashboard-content_profile_user {
    padding: 5px;
    height: 50vh;
    overflow-y: scroll;
} */

/* 
#dashboard-content_profile_user::-webkit-scrollbar {
    display: none;
} */

/* 
.img_dashboardupload {
    width: 100px;
    height: 100px;
    object-fit: cover;
    clip-path: circle(50% at 50% 50%);
    padding: 10px;
} */

.label {
  position: absolute;
  top: 2rem;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
}

input#input {
  display: inline-block;
  width: 30%;
  cursor: pointer;
  opacity: 0;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  box-sizing: border-box;
  background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
    center center no-repeat;
  background-size: 60px 60px;
  /* transform: translate(-3px, -105px); */
}

/* 
@media screen and (max-width: 950px) {
    input#input {
        display: inline-block;
        width: 30%;
        padding: 120px 0 0 0;
        height: 100px;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png") center center no-repeat;
        background-size: 60px 60px;
        transform: translate(-3px, -105px);
    }
} */

.usersname_dashboard {
  font-size: 24px;
  font-weight: 200;
  position: relative;
  right: 190px;
  margin-bottom: 20px;
}

.dashboard_users_components {
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: 50px;
  padding: 20px;
}

@media screen and (max-width: 750px) {
  .dashboard_users_components {
    margin: 0px;
  }
}

.users_details_userscomponents {
  flex-direction: column;
  align-items: stretch;
}

.search_dashboardcomponents {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.paracenter_dashboard {
  margin: 30px;
  font-size: 22px;
}

.content_dashboard {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background: var(--main-bg-color);
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 0%,
    rgba(0, 212, 255, 1) 88%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoutbtn {
  width: 150px;
  height: 80px;
  border-radius: 7px;
  /* width: 9%;
    height: 100%;
    margin: 15%; */
  margin-inline: -184px;
  padding: 5px;
  /* margin-top: 45px; */
  margin-left: 89px;
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

@media screen and (max-width: 750px) {
  .logoutbtn {
    width: 100%;
    height: 80px;
    margin-inline: 0px;
    margin-left: 0px;
  }
}

.container_dashboard {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  border-radius: 2rem;
  z-index: 2;
  display: flex;
}

.img_dashboardupload {
  width: 100px;
  height: 100px;
  object-fit: cover;
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
}

.imageuploader {
  width: 800px;
  height: 400px;
  background-color: #cbeefc;
  padding: 10px;
}

.img-holder span {
  margin-left: 1rem;
  font-family: cursive;
  font-size: 21px;
}

@supports (
  ((-webkit-backdrop-filter: blur(2rem)) or (backdrop-filter: blur(2rem))) or (-webkit-backdrop-filter: blur(2rem))
) {
  .container_dashboard {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.3)
    );
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
  }
}

.dashboard {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
}

.link {
  display: flex;
  padding: 1rem;
  margin: 1rem 0rem;
  align-items: center;
}

.link i {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  font-size: 40px;
}

.user,
.link h2 {
  display: none;
}

.main_center_dashboard {
  flex: 3 1;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.heading_dashboard p {
  color: #658ec6;
  font-weight: 500;
  opacity: 0.8;
}

.name_users_dashboard {
  color: #426696;
  font-weight: 600;
  opacity: 0.8;
}

.btn_pay_dashboard {
  background-color: black;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

@media screen and (min-width: 640px) {
  .link i {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .link {
    margin: 2rem 0rem;
    padding: 1rem 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .container_dashboard {
    width: 80%;
  }
  .dashboard {
    justify-content: space-evenly;
  }
  .user {
    display: block;
  }
  .user img {
    border-radius: 50%;
  }
  .link h2 {
    display: block;
    padding: 0 2rem;
  }
  .main_center_dashboard {
    margin: 5rem;
  }
  .main_second_center_dashboard {
    margin-bottom: 3rem;
  }
}

@media (max-width: 435px) {
  .dashboard_users_components {
    width: 100%;
    font-size: 14px;
  }

  .row .col h5 {
    font-size: 16px;
  }
}

.card_otpcomponents {
  width: 650px;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  height: 390px;
  position: relative;
}
.card_forgetotpcomponents {
  width: 750px;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  height: 790px;
  position: relative;
}
.heading_otpcomponent {
  color: #0fc0fc;
}
.input_formcontrol_forget {
  width: 50%;
  padding: 20px;
  margin: auto;
}
/* .otp_component {
  height: 100vh;
  background: #eee;
} */
.otp_component {
  margin-top: 80px;
}
.forgetotpcomponents {
  background: #eee;
}
.not-receive_otpcomponents {
  color: #989696b8;
  font-size: 15px;
  margin: 50px;
  text-align: center;
}

.form_otpcomponent {
  margin-right: 12px;
}

.form_otpcomponent:focus {
  color: #0fc0fc;
  background-color: #fff;
  border-color: #0fc0fc;
  outline: 0;
  box-shadow: none;
}
.form_otpcomponentforget {
  margin-right: 12px;
}

.form_otpcomponentforget:focus {
  color: #0fc0fc;
  background-color: #fff;
  border-color: #0fc0fc;
  outline: 0;
  box-shadow: none;
}
.forget_form_container {
  text-align: center;
  box-shadow: 5px 5px 5px 5px #888888;
  width: 50%;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
}
.verify_otpcomponents {
  background-color: #0fc0fc;
  border: none;
  color: white;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
}
.cursor {
  cursor: pointer;
  color: #0fc0fc;
}

.otpforget_container {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}
.otpforget_container input[type="text"],
.otpforget_container input[type="email"],
.otpforget_container input[type="phone"],
.otpforget_container input[type="number"],
.otpforget_container textarea {
  width: 90%;

  border: 1px solid black;
  box-shadow: 0 0 1px 1px white;
}

.otpforget_container .panel {
  border: 2px solid black;
  height: 90%;
  margin: 20px;
  padding: 20px;
  width: 190%;
}
.otpforget_container .panel .panel-heading {
  margin-bottom: 20px;
}
.otpforget_container .btn-group {
  width: 90%;
  margin: 10px auto 10px auto;
}
.otpforget_container .back {
  float: left;
}

/* Multi-Part CSS */
input[type="checkbox"] {
  position: absolute;
  /* Position the checkbox in upper left corner */
  opacity: 0;
}

/* Styling Parts */
#part1,
#part2,
#part3 {
  z-index: 2;
  display: block;
  height: 80%;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

/* Hidden Parts */
#part2,
#part3 {
  opacity: 0;
  height: 0;
}

/* Show Step 2 & Hide Step 1 */
#step2:checked ~ #part2 {
  opacity: 1;
  height: auto;
}

#step2:checked ~ #part1 {
  opacity: 0;
  height: 0;
  display: none;
}

/* Show Step 3  & Hide Step 2 */
#step3:checked ~ #part3 {
  opacity: 1;
  height: auto;
}

#step3:checked ~ #part2 {
  opacity: 0;
  width: 0;
  height: 0;
}

.bordered {
  width: 100%;
  background-color: whitesmoke;
  display: flex;
}

.card_body {
  height: 420px;
  padding: 35px;
  width: 50%;
  font-size: 2em;
  overflow-y: scroll;
  min-height: 0px;
}

.card_body::-webkit-scrollbar {
  display: none;
}

.card_body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.card-description {
  width: 100%;
  font-size: 18px;
  margin-top: 18px;
}

.img_random {
  /*   
  width: 81%;
  float: right;
  margin: -422px;
  margin-right: -468px; */
  width: 50%;
  padding: 35px;
  height: 420px;
}

.img_body {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

@media screen and (max-width: 750px) {
  .bordered {
    flex-direction: column;
  }
  .card_body {
    width: 100%;
  }
  .img_random {
    width: 100%;
  }
}

.touristSpots {
  width: 45%;
  margin: 31px;
  display: inline;
}

.body_touristSpots {
  padding: 15px;
}

.touristSpots {
  font-size: 18px;
  font-weight: 500;
}

#tourist_spots_ul {
  padding: 10px;
}

#tourist_spots_li {
  list-style-type: circle;
}

/* 
#request_booking_btn {
  justify-content: center;
  width: 14%;
  margin-left: 43%;
} */

.map_frame {
  width: 100%;
  height: 100%;
  border: 0;
}

.map {
  width: 85%;
  display: inline-block;
  margin: 31px;
}

@media screen and (max-width: 580px) {
  .second_row {
    flex-direction: column;
  }
  .touristSpots {
    width: 90%;
    margin-left: 5%;
  }
  .map {
    width: 90%;
    margin-left: 5%;
  }
}

.second_row {
  display: flex;
}

.h1-thirdCity {
  font-size: 2em;
}

.third-row {
  margin: 29px;
}

.subheading-third {
  font-size: 18px;
}

.DateTime {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0 auto;
  justify-content: center;
  padding: 10px;
  height: auto;
}

.datediv, .timediv{
  display: flex;
  flex-wrap: nowrap;
  font-size: 18px;
  font-weight: bolder;
  align-items: baseline;
}

.datediv label, .timediv label{
  white-space: nowrap;
}

.date {
  padding-right: 9px;
  margin: 4px;
}

.time {
  margin: 4px;
}

.input_date, .input_time{
  width: 20rem;
  border: 2px solid black;
}

.input_time{
  font-size: 20px;
}

#request_booking_btn {
  margin: 2rem auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  padding: 0.4rem 0.8rem;
  white-space: nowrap;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  align-items: center;
  border-radius: 0.2rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (max-width: 700px) {
  .card-description,
  .touristSpots {
    font-size: 14px;
  }
}

.featuredPhotographer-cityHome__container {
  width: 100%;
  /* height: max-content; */
  /* padding: 5em; */
  margin: 50px  0px auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media (max-width: 500px) {
  .featuredPhotographer-cityHome__container {
    padding: 1em;
  }
}

.fourth-row h1 {
  letter-spacing: 2px;
  font-size: 2em;
  padding: 10px;
}

.fourth-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 4rem;
  width: 100%;
  max-width: 1280px;
}

.city_cards {
  width: 100%;
  border-radius: 20px 20px 20px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  min-height: 450px;
  background: rgb(196, 195, 195);
}

.background {
  width: 100%;
  height: 170px;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 170px;
}
/* 

.background {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.background >div{
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 170px;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  background: #74ABE2;
  justify-content: center;
  align-items: center;
   
} */

/* 
.background img{
  width: 100%;
  height: 170px;
  border-radius: 20px 20px 0px 0px;
} */

.carousel-item {
  height: 100vh;
  min-height: 300px;
  z-index: 1;
}

.profileimage img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  background-position: center;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 120px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 10000;
}

.nameph {
  width: 100%;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  top: 70px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.description_ph {
  width: 100%;
  height: 150px;
  font-size: 15px;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  top: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.description_ph p {
  font-size: 15px;
}

@media (max-width: 1024px) {
  .input_date, .input_time{
    width: 18rem;
  }
}

@media (max-width: 540px) {
  .DateTime{
    flex-direction: column;
    width: 80%;
    margin: 29px;
    padding: 0;
  }

  .input_date, .input_time{
    width: 100%;
  }
}






.PhotographerCard_card-container__1WK20 {
  width: 100%;
  max-width: 430px;
  height: 720px;
  background: #fff;
  border-radius: 6px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* box-shadow: 0px 1px 10px 1px #00000044; */
  overflow: hidden;
  display: inline-block;
  background-color: #eff3fa;
  margin-bottom: 3em;
}
@media (max-width: 600px) {
  .PhotographerCard_card-container__1WK20 {
    max-width: 100%; /* Adjust the width for smaller screens */
  
    margin-bottom: 2em;
  }
}
/* .upper-container {
  height: 150px;
  background: #7f00ff;
} */
.PhotographerCard_image-slider__container__2-GcX {
  /* max-width: 800px; */
  width: 100%;
  height: 45%;
  /* border: 7px solid #fff; */
  position: relative;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); */
  /* overflow: hidden; */
  /* background: #7f00ff; */
}
.PhotographerCard_image-box__10LC6 {
  display: flex;
  position: relative;
  height: 100%;
  width: 400%;
  justify-content: space-between;
}
.PhotographerCard_image-box__10LC6 {
  /* animation: imgBox 10s linear infinite; */
}
@-webkit-keyframes PhotographerCard_imgBox__3899p {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -300%;
  }
}
@keyframes PhotographerCard_imgBox__3899p {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -300%;
  }
}
/* .image-box:hover {
  animation-play-state: paused;
} */
.PhotographerCard_image-box__10LC6 .PhotographerCard_image__7OYtQ {
  width: calc(100% - 100px);
  /* width: 100%; */
  /* height: 100%; */
}
.PhotographerCard_image-box__10LC6 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.PhotographerCard_image-container__3zpZu {
  background: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 5px;
  -webkit-transform: translate(0, -3em);
          transform: translate(0, -3em);
  /* float: right; */
  margin-left: auto;
  
  margin-right: 20px;
  
}
.PhotographerCard_image-container__img__1a2Qo {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.PhotographerCard_lower-container__NkCHr {
  height: 35%;
  /* background: #fff; */
  padding: 20px;
  padding-top: 0;
  text-align: center;
  text-transform: capitalize;
}
.PhotographerCard_lower-container__NkCHr h3,
h4 {
  box-sizing: border-box;
  line-height: 0.6;
  font-weight: lighter;
}
.PhotographerCard_lower-container__NkCHr h4 {
  color: #007bff;
  /* opacity: 0.6; */
  font-weight: bold;
  margin: 0.8em 0 0.8em 0;
}
.PhotographerCard_lower-container__NkCHr p {
  font-size: 16px;
  color: black;
  margin-bottom: 30px;
}
.PhotographerCard_lower-container__NkCHr .PhotographerCard_btn__mqUe5 {
  padding: 12px 20px;
  background: #7f00ff;
  border: none;
  color: white;
  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease-in;
}
.PhotographerCard_lower-container__NkCHr .PhotographerCard_btn__mqUe5:hover {
  background: transparent;
  color: #7f00ff;
  border: 2px solid #7f00ff;
}

.PhotographerCard_request-button__39MNw{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

@media (max-width: 500px) {
  .PhotographerCard_card-container__1WK20 {
    width: 100%;
  }
  .PhotographerCard_lower-container__NkCHr h3,
  h4 {
    font-size: 1.5rem;
  }
  .PhotographerCard_image-container__3zpZu {
    -webkit-transform: translate(0, -3em);
            transform: translate(0, -3em);
  }
}

@media (max-width: 450px) {
  .PhotographerCard_image-container__3zpZu {
    -webkit-transform: translate(0, -3em);
            transform: translate(0, -3em);
  }
}

@media (max-width: 400px) {
  .PhotographerCard_image-container__3zpZu {
    -webkit-transform: translate(0, -3em);
            transform: translate(0, -3em);
  }
}


.PhotographerCard_containertext__3ikUv {
  display: flex;
  height: 90px;
  font-size: 16px; 
  font-weight: bold; 
  text-transform: capitalize;
  
}


/* .arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-400%);
 
} */

.PhotographerCard_prev-button__Znd5f,
.PhotographerCard_next-button__30yFI {
  background: none;
  border: none;
  /* color: #000; */
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  background: rgb(0,0,0,.2);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 10px;
  transition: background-color 0.3s ease-in-out;
}

.PhotographerCard_prev-button__Znd5f {
  left: 0;
}

.PhotographerCard_next-button__30yFI {
  right: 0;
}

.PhotographerCard_active-slide-0__3sTGF {
  margin-left: 0;
}
.PhotographerCard_active-slide-1__nTTmh {
  margin-left: -280%;
}
/* .active-slide-2 {
  margin-left: -200%;
}
.active-slide-3 {
  margin-left: -300%;
} */



.background_contact {
  background: url(/static/media/backgroundcontact.4d87cf8b.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  max-width: 200vh;
}
.contactuscomponents_text {
  color: aliceblue;
}
.contactuscomponents_text p {
  text-align: left;
}
.form_contactus {
  margin: 20px;
  padding: 20px;
  max-height: 100%;
  -webkit-transform: translateY(10%);
          transform: translateY(10%);
}

#contactname {
  margin: 10px;
  padding: 20px;
  outline: none;
}
#contactemail {
  margin: 10px;
  padding: 20px;
  outline: none;
}
#contactphone {
  margin: 10px;
  padding: 20px;
  outline: none;
}
.msg_contactus textarea {
  outline: none;
  max-width: 100%;
  margin: 10px;
}
.button_contactuspage button {
  background-color: #87ceeb;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.social_address_contactus {
  display: flex;
  justify-content: space-evenly;
  color: white;
  font-size: 25px;
  margin: 10px;
  padding: 20px;
}

.social-media-contactuspage {
  font-size: 25px;
  -webkit-transform: translateY(5%);
          transform: translateY(5%);
}
.social-media-contactuspage a {
  color: white;
  margin: 20px;
}
@media (max-width: 767px) {
  .social_address_contactus {
    display: block;
  }
  .background_contact {
    height: 200vh;
  }
}

.heading_become {
  margin-top: 58px;
}


@media (max-width: 540px) {
  .heading_become h1{
   font-size: 24px;
   font-weight: 600;
  }
  
}

.sub_heading_become {
  /* margin-top: 45px; */
  margin-left: 90px;
  padding-top: 20px;
}

.left_become {
  width: 31%;
  margin-left: 39px;
}

.left_become .img_icon {
  width: 17%;
  height: 46px;
  margin: -78px;
  margin-top: 24px;
}

.become {
  margin-inline: 131px;
}
.right_become2 {
  width: 34%;
  float: right;
  margin-top: -371px;
  margin-right: 55px;
}

.img_become {
  width: 35%;
  aspect-ratio: 1/1;
  float: right;
  margin: 0 123px;
  /* margin-top: -40px; */
  /* height: 530px; */
}
.img_icon2 {
  width: 5%;
  margin-inline: -37px;
  margin-top: -126px;
  margin-bottom: -201px;
}
.left_become2 {
  margin-inline: -25px;
  margin-top: 65px;
  width: 42%;
}
.img_icon2right {
  width: 5%;
  margin-inline: 60%;
  margin-top: -70%;
}
.leftside {
  width: 68%;
  margin-left: 49px;
  margin: 149px;
}
.rightside {
  width: 68%;
  float: right;
  margin-top: -591px;
  margin-right: -407px;
}
.sub_heading_become2 {
  margin-top: 83px;
  margin-left: 92px;
  /* margin-bottom: -130px; */
  margin-bottom: 0px;
  padding-top: 20px;
}
.img_become3 {
  width: 40%;
  float: left;
  margin: 40px;
  margin-top: 62px;
  /* height: 518px; */
}
.sub_heading_become3 {
  margin-top: 83px;
  margin-left: 74px;
  margin-bottom: -120px;
}

.sub_heading_become h3 {
  padding-left: 15px;
  font-weight: 600;
}
.list_right {
  margin-top: 3%;
  width: 50%;
}

.list_right ol {
  padding-left: 30px;
}
.list_right li {
  list-style: decimal;
}

.list_right li::marker {
  font-size: 1.5rem;
  line-height: 0.6rem;
}

.list_right_wrapper {
  margin-left: 1rem;
}
.list_number {
  margin-bottom: -25px;
  margin-inline: -40px;
  font-size: 18px;
  font-weight: 600;
}
.last_part {
  margin-inline: 80px;
  font-size: 16px;
}
.last {
  margin-top: 5%;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn_form {
  align-items: center;
}
.form_content {
  width: 100%;
  align-items: center;
  margin-inline: -25%;
  margin-bottom: 29px;
}
.button_form {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  width: 159px;
  border-radius: 5px;
  padding: 10px;
}
.button_form:hover {
  background-color: #0372e9;
  color: white;
  border-color: #007bff;
  width: 159px;
  border-radius: 5px;
  padding: 10px;
}
.para_career {
  font-size: 18px;
}
.second_para {
  margin-top: 60px;
}
.upload_resume {
  position: relative;
  overflow: hidden;
  align-items: center;
  margin-inline: 42%;
  margin-top: 20px;
}
.input_resume {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.image_space {
  align-items: center;
  margin-inline: 30%;
  margin-top: 20px;
}
.side_image {
  float: right;
  margin-right: 70px;
}
.form_Partner {
  width: 47%;
  align-items: center;
  margin-inline: 5%;
  margin-bottom: 29px;
}
.select_dropdown {
  width: 100%;
  border: 1px solid lightgray;
  padding: 7px;
  border-radius: 6px;
}
.contactus {
  background-color: aliceblue;
  padding: 2%;
}
.contact {
  width: 63%;
  float: right;
  margin: 33px;
  padding: 21px;
}
.message {
  width: 60%;
  -webkit-transform: translate(60%, -40%);
          transform: translate(60%, -40%);
}
.contactpageimg {
  -webkit-transform: translateX(55%);
          transform: translateX(55%);
}

.info {
  float: left;
  margin-inline: 67px;
  margin-top: -235px;
  width: 20%;
}
.phoneno {
  display: contents;
}
.info_img {
  padding-right: 10px;
  width: 20%;
}
.sendmsg_button {
  width: 55%;
  margin-inline: 142px;
  margin-top: -10px;
  -webkit-transform: translate(55%, -270%);
          transform: translate(55%, -270%);
}
.send_button {
  margin-top: 98px;
}
.social_media {
  width: 19%;
}

.head3 {
  font-weight: bolder;
  font-size: xx-large;
}

@media (min-width: 1100px) {
  .container-3 .list_right {
    padding: 0 100px;
  }
}

@media (max-width: 1100px) {
  .list_number {
    display: block;
  }

  .become {
    margin-inline: 121px;
  }
  .sub_heading_become {
    text-align: center;
    margin-left: 0;
  }

  .main-container {
    /* margin-top: 1rem; */
    display: flex;
    flex-direction: column;
    /* border-radius: 16px; */
    /* background-color: #def5ff; */
  }

  .main-container div .img_become {
    margin: 2rem auto;
    display: flex;
    float: unset;
    /* height: 520px; */
  }

  .main-container .become {
    display: flex;

    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .sub_heading_become2 {
    margin-left: 0;
  }
  .sub_heading_become2 h3 {
    text-align: center;
  }

  
@media (max-width: 540px) {
  .sub_heading_become2 h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
}

  /* ========= 3rd container =============  */

  .container-3 .list_right {
    width: 80%;
    /* padding: 0 53px; */
    margin: auto;
  }

  .container-3 .list_right .list_number {
    margin-bottom: 0;
  }

  .container-3 .list_right li .desc_how {
    margin: auto;
    margin-bottom: 18px;
  }

  .last {
    margin-top: 39px;
  }
  .last h3 {
    font-size: 22px;
  }

  .last .button_form a {
    font-size: 1rem;
  }
}

@media (max-width: 777px) {
  .main-container div .img_become {
    width: 45vw;
    /* height: 66vh; */
  }

  .main-container .become {
    flex-direction: column;
  }

  .left_become {
    width: 50%;
    margin-left: 0;
  }

  .left_become .card_content {
    text-align: center;
  }
}

@media (max-width: 675px) {
  .main-container div .img_become {
    width: 55vw;
    /* height: 66vh; */
  }
}

@media (max-width: 500px) {
  .main-container div .img_become {
    margin-top: 1.5rem;
    width: 75vw;
    /* height: 50vh; */
  }

  .left_become {
    width: 71%;
  }

  .left_become .img_icon {
    width: 22% !important;
  }

  .left_become .card_content {
    width: 50vw !important;
  }

  .left_become .card_content h4 {
    font-size: 18px !important;
  }

  .left_become .card_content p {
    font-size: 15px !important;
  }
}

@media (max-width: 390px) {
  .main-container div .img_become {
    /* height: 47vh !important; */
  }

  .left_become .img_icon {
    width: 46% !important;
  }
}
.heading_career {
  margin: 20px 0;
}

.image_space {
  height: 25vh;
}

.upload_resume {
  display: flex !important;
  justify-content: center !important;
  width: 48vw !important;
  margin: auto !important;
  align-items: center !important;
  text-align: center !important;
  margin-top: 5vh !important;
  margin-bottom: 4vh !important;
}

.second {
  text-align: center;
  font-size: 18px;
}
.second h4 {
  line-height: 1.6;
  padding: 20px;
}

.main {
  text-align: center;
  padding: 15px;
}

.main h1 {
  padding: 10px;
  font-weight: 700;
}

@media (max-width: 540px) {
  .main h1 {
    font-size: 28px;
    font-weight: 600;
  }
}

.main p {
  font-size: 18px;
}

.join {
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.send {
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
/* .input_resume {
  width: 48%;
  margin-left: 2vw;
} */

input[type="file"] {
  display: none;
}

.upload_btn label {
  color: white;
  height: 60px;
  width: 200px;
  background-color: #007bff;
  /* position: absolute; */
  margin: auto;
  margin-top: 6vh;
  margin-bottom: 4vh;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.upload_btn label:hover {
  background-color: #026bdb;
}

@media (max-width: 1200px) {
  .upload_resume {
    font-size: 18px;
  }
}
@media (max-width: 1100px) {
  .upload_resume {
    font-size: 17px;
  }
}
@media (max-width: 1000px) {
  .upload_resume {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .upload_resume {
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .upload_resume {
    font-size: 1.5vw;
  }
}
.looking_container {
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

/* .left_part {
  width: 42%;
} */

.looking_container .content {
  width: 80%;
}

.looking_container h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.part1 {
  margin: 50px 0;
}

.icon img {
  width: 11%;
}

/* .main-container div .img_become {
  height: 80vh;
  width: 35%;
} */

img-become__new .right_part {
  width: 42%;
}

.right_part .part2 .content {
  width: 53%;
}

@media (max-width: 860px) {
  .looking_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #d9f4ff; */
  }

  .looking_container .content p {
    text-align: center;
  }

  .looking_container h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
  }

  .icon img {
    width: 15%;
  }

  .looking_container .content {
    margin: auto;
    width: 100%;
  }

  .left_part,
  .right_part {
    width: 75%;
    text-align: center;
  }

  .right_part .part2 .content {
    width: 100%;
  }

  .becomFodrixographer-p__font {
    font-size: 15px;
  }
}

.top_image {
    display: flex;
    justify-content: center;
}

.partner_form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.form_content_part {
    width: 35vw;
}

@media (max-width: 1029px) {
    .top_image {
        width: 100%;
        margin: auto;
    }

    .partner_form {
        margin: auto;
    }

    .form_content_part {
        width: 80vw;
    }
}

@media (max-width: 429px) {
    .top_img {
        width: 100%;
    }
}

.main_container_ctus {
    min-height: 100vh;
    width: 100%;
    background: aliceblue;
    display: flex;
    align-items: center;
    justify-content : center;
  }
  .container_ctus {
    width: 85%;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
  }
  .container_ctus .content_ctus {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container_ctus .content_ctus .left-side_ctus {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .content_ctus .left-side_ctus::before {
    content: "";
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: #afafb6;
  }
  .content_ctus .left-side_ctus .details_ctus {
    margin: 14px;
    text-align: center;
  }
  .content_ctus .left-side_ctus .details_ctus i {
    font-size: 30px;
    color: #1e77d7;
    margin-bottom: 10px;
  }
  .content_ctus .left-side_ctus .details_ctus .topic {
    font-size: 18px;
    font-weight: 500;
  }
  .content_ctus .left-side_ctus .details_ctus .text-one,
  .content_ctus .left-side_ctus .details_ctus .text-two {
    font-size: 18px;
    color: #000;
  }
   
   
   
  .content_ctus .left-side_ctus .social_ctus i {
    font-size: 25px;
  }
   
  .container_ctus .content_ctus .right-side_ctus {
    width: 75%;
    margin-left: 75px;
  }
  .content_ctus .right-side_ctus .topic-text {
    font-size: 40px;
    font-weight: 600;
    color: #007bff;
  }

  @media (max-width: 540px) {
    .content_ctus .right-side_ctus .topic-text {
      font-size: 24px;
      font-weight: 600;
    }
  }
   
  .content_ctus .right-side_ctus .topic_para {
    margin: 14px 0;
  }
   
  .right-side_ctus .input-box_ctus {
    height: 50px;
    width: 100%;
    margin: 12px -5px;
  }
  .right-side_ctus .input-box_ctus input,
  .right-side_ctus .input-box_ctus textarea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #f0f1f8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .right-side_ctus .message-box {
    min-height: 110px;
  }
  .right-side_ctus .input-box_ctus textarea {
    padding: 20px;
    margin: 10px;
  }
  .right-side_ctus .button_ctus {
    display: inline-block;
    margin-top: 12px;
  }
  .right-side_ctus .button_ctus button[type="submit"] {
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #007bff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .right-side_ctus .button_ctus button[type="submit"]:hover {
    background: #005bbc;
  }
   
  @media (min-width: 773px) {
    .content_ctus .left-side_ctus .social_ctus {
      color: #52a0f3;
      width: 14vw;
      display: flex;
      justify-content: space-evenly;
      margin-top: 8px;
    }
  }
   
  @media (max-width: 950px) {
    .container_ctus {
      width: 90%;
      padding: 30px 40px 40px 35px;
    }
    .container_ctus .content_ctus .right-side_ctus {
      width: 75%;
      margin-left: 55px;
    }
  }
  @media (max-width: 820px) {
    .container_ctus {
      margin-top: 40px;
      margin-bottom: 40px;
      height: 100%;
    }
    .container_ctus .content_ctus {
      flex-direction: column-reverse;
    }
    .container_ctus .content_ctus .left-side_ctus {
      width: 100%;
      flex-direction: row;
      margin-top: 40px;
      justify-content: center;
      flex-wrap: wrap;
    }
   
    .content_ctus .left-side_ctus .social_ctus {
      margin-top: 9px;
    }
   
    .container_ctus .content_ctus .left-side_ctus::before {
      display: none;
    }
    .container_ctus .content_ctus .right-side_ctus {
      width: 100%;
      margin-left: 0;
    }
  }
   
  @media (max-width: 772px) {
    .content_ctus .left-side_ctus .social_ctus i {
      padding: 0 5px;
    }
  }
   
  
  
  
  
/* html,
body {
  position: relative;
  height: 100%;
} */

/* body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */



figure.snip1157 * {
  box-sizing: border-box;
  font-weight:700;
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
figure.snip1157 img {
  max-width: 100%;
  vertical-align: middle;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 40px 0 0 10px;
  /* object-fit:contain ; */
}
figure.snip1157 blockquote {
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: rgb(239, 246, 248);
  padding: 25px 50px 30px 50px;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  line-height: 1.2em;
  text-align: justify;
  /* font-style: italic; */
}
figure.snip1157 blockquote:before,
figure.snip1157 blockquote:after {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure.snip1157 blockquote:before {
  top: 25px;
  left: 20px;
}
figure.snip1157 blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 0;
}
figure.snip1157 .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid rgb(239, 246, 248);
  margin: 0;
  position: absolute;
}
figure.snip1157 .author {
  position: relative;
  top:-85px;
  padding: 0 10px 0 120px;
  margin: 0;
  text-transform: uppercase;
  color: rgba(0,0,0,.8);;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
figure.snip1157 .author h6 {
  /* opacity: 0.8; */
  margin-top: 5px;
  font-weight: 800;
  /* font-style: italic; */
}
figure.snip1157 .author h6 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
  /* font-style: italic; */
}

.heading_testimonial_components {
  font-size: 2.5em;
  font-weight: 700;
  padding: 20px;
}

.swiper-container {
  width: 90%;
  height: 550px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 330px;
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 54px;
}
.swiper-slide img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 575px) {
  .swiper-slide {
    display: flex;
    min-height: 40vh;
    flex-direction: column;
    margin: 0;
  }
  @media screen and (max-width: 524px) and (min-width: 320px) {
    .heading_testimonial_components {
      font-size: 24px;
    }
  }
}

.DashboardBooking_NewDashboard-Body__container__1Cz40 {
  width: 85%;
  min-height: 100vh;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  /* height: 100vh; */
  padding: 1em;
  background-color: #eff3fa;
}

.DashboardBooking_NewDashboard-header__BBopc {
  height: 10vh;
  display: flex;
  align-items: center;
  position: relative;
}

.DashboardBooking_NewDashboard-header__BBopc .DashboardBooking_menu-btn__Pr3OM{
  display: none;
  font-size: 2rem;
  margin-right: 1rem;
}

.DashboardBooking_NewDashboard-header__BBopc .DashboardBooking_dashboard-heading__KofdZ{
  font-size: 2rem;
  margin: 0;
}

.DashboardBooking_NewDashboard-header__BBopc button {
  width: 12em;
  height: 3em;
  border-radius: 5em;
  border: none;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
}

.DashboardBooking_NewDashboard-header__BBopc a {
  position: absolute;
  right: 1rem;
}

.DashboardBooking_NewDashboard-header__BBopc .DashboardBooking_bookAShoot_btn-link__3xYWK {
  display: none;
}

.DashboardBooking_NewDashboard-header__BBopc .DashboardBooking_bookAShoot_btn__2jOw3 {
  font-size: 2rem;
  color: #007bff;
}

.DashboardBooking_NewDashboard-header__BBopc button:hover {
  background-color: #0069d9;
}

.DashboardBooking_NewDashboard-booking__route__3twLc {
  height: 5vh;
  /* border: 1px solid black; */
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid gray;
  box-sizing: border-box;
}

.DashboardBooking_NewDashboard-booking__link__2phYB {
  height: 100%;
  margin-right: 1em;
  text-decoration: none;
  color: black;
  margin-bottom: -2px;
}

/*temperarily on hover to be changed to focus*/
.DashboardBooking_NewDashboard-booking__link__2phYB:hover {
  box-sizing: border-box;
  border-bottom: 5px solid #007bff;
  border-radius: 5px;
}

.DashboardBooking_NewDashboard-photoshootCard__by2WX {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
}

@media (max-width: 810px) {
  .DashboardBooking_NewDashboard-Body__container__1Cz40 {
    width: 100%;
  }

  .DashboardBooking_NewDashboard-photoshootCard__by2WX {
    justify-content: center;
  }

  .DashboardBooking_NewDashboard-header__BBopc .DashboardBooking_menu-btn__Pr3OM{
    display: block;
  }
}

@media (max-width: 645px) {
  .DashboardBooking_NewDashboard-Body__container__1Cz40 {
    height: -webkit-max-content;
    height: max-content;
  }

  .DashboardBooking_NewDashboard-header__BBopc button {
    /* width: 10em;
    height: 2.5em; */
    /* display: none; */
    width: auto;
    height: auto;
    padding: 4px 8px;
    font-size: 12px;
  }

  .DashboardBooking_NewDashboard-header__BBopc .DashboardBooking_bookAShoot_btn-link__3xYWK{
    display: block;
  }
}
/* .pending-booking__container {
  width: 100%;
  height: auto;
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  border-radius: 1em;
  background-color: #fff;
  box-shadow: 2px 2px 5px #ddd;
}

.pending-booking__container h6 {
  margin-bottom: 0;
}

.pending-booking__container p {
  margin-bottom: 0;
} */

.ConfirmedBookings_NewDashboard-photoshootCard__18jtw {
  width: 100%;
  height: auto;
  display: flex;
  /* flex-flow: row wrap; */
  flex-wrap: wrap;
}
.ConfirmedBookings_pending-booking__container__3EKCr {
  width: 16em;
  height: 17em;
  /* border: 1px solid black; */
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  border-radius: 0.5em;
  background-color: #fff;
  box-shadow: 0px 0px 9px #00000029;
  border: 2px solid #d1d1d1;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 100;
}

.ConfirmedBookings_pending-booking__container__3EKCr h6 {
  margin-bottom: 0;
}

.ConfirmedBookings_pending-booking__inner__3wo85 h3 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 1.3rem;
  text-shadow: 2px 2px #eaeaea;
}

.ConfirmedBookings_pending-booking__container__3EKCr p {
  margin-bottom: 0;
}

.ConfirmedBookings_pendingBookings-action__container__2BWPV {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.ConfirmedBookings_no_confirm_booking_heading__1z26J{
  margin-top: 2rem;
}

@media (max-width: 810px) {
  .ConfirmedBookings_NewDashboard-photoshootCard__18jtw {
    justify-content: center;
  }
}

.DashboardNav_DashboardNav-close_icon__oASs7{
  position: relative;
}

.DashboardNav_DashboardNav-close_icon__oASs7 .DashboardNav_closeMenu-btn__ErOTZ{
  display: none;
  position: absolute;
  right: 0;
  top: 0.5rem;
  font-size: 2rem;
}

.DashboardNav_DashboardNav-container__11MGf {
  width: 15%;
  height: 100vh;
  text-align: center;
  padding: 0.5em;
  border-right: solid 1px #8989893d;
  transition-property: display;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

.DashboardNav_DashboardNav-header__container__1qkKr {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-bottom: 1px solid #ddd;
}

.DashboardNav_DashboardNav-route__container__3IW9x {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-bottom: 1px solid #ddd;
  padding: 1em 2em;
}

.DashboardNav_DashboardNav-route__38t5U {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DashboardNav_DashboardNav-route__38t5U:hover {
  text-decoration: none;
}

.DashboardNav_DashboardNav-route__38t5U i {
  margin-right: 0.6em;
}

.DashboardNav_DashboardNav-route__38t5U p {
  margin-bottom: 0;
  color: black;
}

.DashboardNav_DashboardNav-route__38t5U.DashboardNav_logout-btn__1GbeK{
  cursor: pointer;
}

.DashboardNav_DashboardNav-UserInfo__cIESp img {
  margin-right: 0.6em;
}

@media (max-width: 1110px) {
  .DashboardNav_DashboardNav-container__11MGf {
    width: 20%;
  }
}

@media (max-width: 810px) {
  .DashboardNav_DashboardNav-container__11MGf {
    position: absolute;
    width: 25%;
    height: 125vh;
    z-index: 3;
    background-color: #fff;
  }
  
  .DashboardNav_DashboardNav-close_icon__oASs7 .DashboardNav_closeMenu-btn__ErOTZ{
    display: block;
  }

  .DashboardNav_DashboardNavDisplay__1EJH6 {
    display: none !important;
  }
}

@media (min-width: 810px) {
  .DashboardNav_hamburgerNavDisplay__1Bcrd {
    display: none;
  }
}
@media (max-width: 710px) {
  .DashboardNav_DashboardNav-container__11MGf {
    width: 30%;
  }
}

@media (max-width: 645px) {
  .DashboardNav_DashboardNav-container__11MGf {
    height: 190vh;
  }
}

@media (max-width: 590px) {
  .DashboardNav_DashboardNav-container__11MGf {
    width: 40%;
  }
}

@media (max-width: 420px) {
  .DashboardNav_DashboardNav-container__11MGf {
    width: 50%;
  }
}

.DashboardProfile_NewDashboard-Body__container__1nj4o {
  width: 85%;
  min-height: 100vh;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  /* height: 100vh; */
  padding: 1em 3em;
  background-color: #eff3fa;
}

.DashboardProfile_NewDashboard-header__237ek {
  height: 10vh;
  display: flex;
  align-items: center;
}

.DashboardProfile_NewDashboard-header__237ek .DashboardProfile_menu-btn__17y6i {
  display: none;
  font-size: 2rem;
  margin-right: 1rem;
}

.DashboardProfile_dashboard-heading__UJLPO {
  font-size: 2rem;
  margin: 0;
}
.DashboardProfile_NewDashboard-header__237ek button {
  width: 12em;
  height: 3em;
  border-radius: 5em;
  border: none;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
}

.DashboardProfile_NewDashboard-header__237ek button:hover {
  background-color: #0069d9;
}

.DashboardProfile_NewDashboard-booking__route__3u2Q_ {
  height: 5vh;
  /* border: 1px solid black; */
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #ccc;
}

.DashboardProfile_NewDashboard-booking__route__3u2Q_ h5 {
  height: 100%;
  border-bottom: 5px solid #007bff;
  border-radius: 4px;
  margin-bottom: -2px;
}

.DashboardProfile_NewDashboard-booking__confirmed__Y3unp {
  margin-right: 1em;
  text-decoration: none;
}

.DashboardProfile_NewDashboard-photoshootCard__s6N_0 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  margin: 1em 0;
}

.DashboardProfile_NewDashboard-photoshootCard__section2__1DtJf {
  width: 55%;
  height: 32em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1125px) {
  .DashboardProfile_NewDashboard-photoshootCard__s6N_0 {
    width: 100%;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .DashboardProfile_NewDashboard-photoshootCard__section2__1DtJf {
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
  }
}
@media (max-width: 810px) {
  .DashboardProfile_NewDashboard-Body__container__1nj4o {
    width: 100%;
    padding: 1em 0.5em;
  }

  .DashboardProfile_NewDashboard-photoshootCard__s6N_0 {
    padding: 1rem;
  }

  .DashboardProfile_NewDashboard-header__237ek,
  .DashboardProfile_NewDashboard-booking__route__3u2Q_ {
    margin-left: 1rem;
  }

  .DashboardProfile_NewDashboard-header__237ek .DashboardProfile_menu-btn__17y6i {
    display: block;
  }
}

@media (max-width: 645px) {
  .DashboardProfile_NewDashboard-Body__container__1nj4o {
    height: -webkit-max-content;
    height: max-content;
  }
  .DashboardProfile_NewDashboard-photoshootCard__s6N_0 {
    display: block;
    /* text-align: center; */
  }
  .DashboardProfile_NewDashboard-photoshootCard__section2__1DtJf {
    width: 100%;
  }
}

/*============ CSS for LEADS ===========*/
.DashboardProfile_table-responsive__2Fe7H{
  position: relative;
}
.DashboardProfile_cell-1__3Ypei {
  cursor: pointer;
}
.DashboardProfile_thead__3J3Sm {
  background: #dddcdc;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.DashboardProfile_thead__3J3Sm th {
  background-color: #007bff;
  color: #fff;
  text-align: center;
} 
.DashboardProfile_table-body__3_Rp4 td {
  text-align: center;
} 
.ProfilePersonalDetails_ProfilePersonalDetails__container__1mFeL label {
  font-size: 14px;
  color: #898989;
  margin: 0 0 0.5em 0;
}
.ProfilePersonalDetails_ProfilePersonalDetails__container__1mFeL input {
  display: block;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 0.2em;
}

.ProfilePersonalDetails_ProfilePersonalDetails__container__1mFeL {
  width: 38%;
  height: 30em;
  background: #fff;
  border-radius: 1em;
  box-shadow: 2px 2px 5px #ddd;
  padding: 1em 1.5em;
  margin-top: 1em;
}

.ProfilePersonalDetails_ProfilePersonalDetails__FormContainer__2-_YK {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: flex-end; */
}

.ProfilePersonalDetails_ProfilePersonalDetails-section1__3OdYW {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProfilePersonalDetails_ProfilePersonalDetails-section1__3OdYW > img{
  margin: 0.5rem auto;
}

.ProfilePersonalDetails_ProfilePersonalDetails-section1__inputContainer__3XakD {
  display: block;
  width: 70%;
}

/* .ProfilePersonalDetails-section1__inputContainer div {
  display: block;
} */

.ProfilePersonalDetails_ProfilePersonalDetails-section2__2hPyX {
  width: 100%;
}

.ProfilePersonalDetails_ProfilePersonalDetails-section3__ERSMy {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ProfilePersonalDetails_ProfilePersonalDetails-section3__ERSMy div:nth-of-type(1) {
  width: 30%;
}

.ProfilePersonalDetails_ProfilePersonalDetails-section3__ERSMy div:nth-of-type(2) {
  width: 65%;
}

.ProfilePersonalDetails_ProfilePersonalDetails-button__3m38j {
  width: 80%;
  height: 2.2em;
  border-radius: 5em;
  border: none;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
  margin: 1rem auto 0;
}

.ProfilePersonalDetails_ProfilePersonalDetails-button__3m38j:hover {
  background-color: #0069d9;
}

@media (max-width: 1125px) {
  .ProfilePersonalDetails_ProfilePersonalDetails__container__1mFeL label {
    font-size: 13px;
  }

  .ProfilePersonalDetails_ProfilePersonalDetails__container__1mFeL {
    width: 100%;
  }

  .ProfilePersonalDetails_ProfilePersonalDetails-section3__ERSMy div:nth-of-type(1) {
    width: 34%;
  }
}

@media (max-width: 645px) {
  .ProfilePersonalDetails_ProfilePersonalDetails__container__1mFeL {
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }

  .ProfilePersonalDetails_ProfilePersonalDetails-section1__3OdYW {
    align-items: flex-start;
    flex-direction: column;
  }
  
  .ProfilePersonalDetails_ProfilePersonalDetails-section1__inputContainer__3XakD {
    display: block;
    width: 100%;
  }
}

.UserAddress_userAddress-container__1NHGj label {
  font-size: 14px;
  color: #898989;
  margin: 0 0 0.5em 0;
}
.UserAddress_userAddress-container__1NHGj input {
  display: block;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 0.2em;
}

.UserAddress_userAddress-container__1NHGj {
  width: 100%;
  height: 56%;
  background: #fff;
  border-radius: 1em;
  box-shadow: 2px 2px 5px #ddd;
  padding: 1em 1.5em;
}

.UserAddress_userAddress-container__1NHGj form {
  display: flex;
  flex-direction: column;
}

.UserAddress_userAddress__section1__Nq591 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 1px solid #ddd;
}

.UserAddress_userAddress__section2__2pYma {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1em;
}

.UserAddress_userAddress__section2__2pYma div {
  width: 48%;
}

.UserAddress_userAddress__section3__-2koh {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.UserAddress_userAddress__section3__-2koh div {
  width: 28%;
}

.UserAddress_userAddress__section3__-2koh div:nth-of-type(1) {
  width: 38%;
}

.UserAddress_UserAddress-button__3cM11 {
  width: 60%;
  height: 2.2em;
  border-radius: 5em;
  border: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
  margin: 1em auto 0;
}

.UserAddress_UserAddress-button__3cM11:hover {
  background-color: #0069d9;
}

@media (max-width: 645px) {
  .UserAddress_userAddress-container__1NHGj {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1.5em;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
    
  .UserAddress_userAddress__section2__2pYma {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1em;
  }

  .UserAddress_userAddress__section2__2pYma div {
    width: 100%;
  }
}

.UserPassword_userPassword-container__BJ5lk label {
  font-size: 14px;
  color: #898989;
  margin: 0 0 0.5em 0;
}
.UserPassword_userPassword-container__BJ5lk input {
  display: block;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 0.2em;
}

.UserPassword_userPassword-container__BJ5lk {
  width: 100%;
  height: 42%;
  background: #fff;
  border-radius: 1em;
  box-shadow: 2px 2px 5px #ddd;
  padding: 1em 1.5em;
}

.UserPassword_userPassword__section1__2Ll1N {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 1px solid #ddd;
}

.UserPassword_userPassword__section2__29tSg {
  display: flex;
  justify-content: space-between;
}

.UserPassword_userPassword__section2__29tSg div {
  width: 48%;
}

.UserPassword_userPassword__section3__2_cVC {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.UserPassword_userPassword__section3__2_cVC div {
  width: 48%;
}

.UserPassword_UserPassword-button__2hRds {
  width: 100%;
  height: 2.2em;
  border-radius: 5em;
  border: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
  margin: 1em auto;
}

.UserPassword_UserPassword-button__2hRds:hover {
  background-color: #0069d9;
}

@media (max-width: 645px) {
  .UserPassword_userPassword-container__BJ5lk {
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }

    
  .UserPassword_userPassword__section2__29tSg,
  .UserPassword_userPassword__section3__2_cVC {
    flex-direction: column;
  }
  
  .UserPassword_userPassword__section2__29tSg div,
  .UserPassword_userPassword__section3__2_cVC div {
    width: 100%;
  }

}

.NewDashboard_NewDashboard-container__sjDuk {
  width: 100%;
  /* min-height: 100vh; */
  /* max-height: fit-content; */
  /* overflow-y: hidden; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.NewDashboard_DashboardNav-close__1aCOg {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0.8em;
  left: 4em;
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  z-index: 4;
}

.NewDashboard_NewDashboard-hamburger__ceNB- {
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: #fff;
}
@media (min-width: 810px) {
  .NewDashboard_NewDashboard-hamburger__ceNB- {
    display: none;
  }

  .NewDashboard_DashboardNav-close__1aCOg {
    display: none;
  }
}

@media (max-width: 645px) {
  .NewDashboard_NewDashboard-container__sjDuk {
    overflow-y: visible;
    /* height: 190vh; */
    background-color: #eff3fa;
  }
}

#collapse {
  justify-content: flex-end;
}

#navbar {
  background-color: aliceblue;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 499;
  box-shadow: 0 6px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
  transition: 0.3s box-shadow,
    0.6s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  transition: 0.6s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow;
  transition: 0.6s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.6s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

a.nav-item.nav-link {
  color: #000 !important;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: 0.1s ease-in;
}

a.nav-item.nav-link:hover {
  color: #007bff !important;
}

* {
  font-family: Merriweather;
}

body {
  overflow-x: hidden;
}

.quote_popup {
  width: 50%;
}

#home_image {
  height: 90vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

#container-top {
  height: 90vh;
  width: 100%;
  position: relative;
  text-align: center;
  background: linear-gradient(rgba(0, 123, 255, 0.05), rgba(0, 0, 0, 0.05)),
    center/cover no-repeat url(/static/media/home_img.3a58f427.webp);
  background-attachment: fixed;
}

#center-search {
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -10%);
          transform: translate(-50%, -10%);
}

#center-text h1 {
  font-size: 45px;
  margin-top: 120px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: rgb(119, 118, 118) 1px 0 10px;
  text-align: center;
}

#center-text p {
  font-size: 30px;
  padding: 5px;
  height: 40px;
  color: rgb(255, 255, 255);
  text-shadow: rgb(119, 118, 118) 1px 0 10px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

@media (max-width: 1300px) {
  #center-search {
    width: 100vw;
  }
}
@media (max-width: 760px) {
  #center-search {
    width: 90vw;
  }
  #center-text h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 650px) {
  /* #center-text {
    width: 100vw;
  } */
  #center-text h1 {
    font-size: 34px;
  }
  #center-text p {
    color: rgb(255, 255, 255);
  }
  #search_home {
    /* width: 50vw; */
    margin-left: 2vw;
  }
}

@media screen and (max-width: 450px) {
  #center-text h1 {
    font-size: 30px;
  }
  #center-text p {
    font-size: 19px;
  }
}

.home_heading {
  font-size: 2em;
  color: black;
  margin-top: -237px;
}

/*search bar styling   */

.search {
  margin-top: 280px;
}

.sb-example-1 {
  max-width: 100%;
}

.sb-example-1 .search {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 0px;
  /* margin-left: 9px; */
  /* margin-right: 619px; */
}

.sb-example-1 .searchTerm {
  width: 100%;
  border: 3px;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  height: 43px;
  background: transparent;
  border: black solid 1px;
}

.sb-example-1 .searchTerm:focus {
  color: #0f0e0e;
}

.sb-example-1 .searchButton {
  width: 50px;
  height: 43px;
  border: 1px solid black;
  background: transparent;
  text-align: center;
  color: black;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.Top-band_home {
  text-align: center;
  background-color: #00008b;
  position: relative;
  z-index: 1;
  width: 100%;
}

p.div2subhead_home {
  margin-right: 250px;
}

.d-block {
  display: block !important;
  height: 690px;
}

.band-top-header_home h1 {
  font-size: 16px;
  font-weight: bold;
  color: #f8f8ff;
}

.home_heading_content {
  color: white;
  position: relative;
  z-index: 2;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_heading_content h3 {
  font-size: 2.2rem;
  color: black;
  margin-top: -330px;
}

.home_heading_content p {
  font-size: 21px;
}

.carousel-item {
  height: 100vh;
  min-height: 300px;
}

.heading_home_components h1 {
  font-size: 2.5em;
  font-weight: 700;
  margin-top: -50px;
}

.heading_Explore {
  font-size: 2.5em;
  font-weight: 700;
  margin-top: 30px;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: -29px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel-caption h5 {
  font-size: 45px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 25px;
  font-family: merienda;
}

.carousel-caption p {
  width: 60%;
  margin: auto;
  font-size: 20px;
  line-height: 1.9;
  font-family: poppins;
}

.carousel-caption a {
  text-transform: uppercase;
  background: #000000;
  padding: 10px 30px;
  display: inline-block;
  color: #fff;
  margin-top: 15px;
}

.button_home {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.button_home:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 10rem;
  z-index: -2;
}

.button_home:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button_home:hover {
  color: #fff;
}

.button_home:hover:before {
  width: 100%;
}

#bookshootnow_home {
  border-radius: 6px;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

#bookshootnow_home:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.secondDiv_home {
  margin: 1em auto;
  padding-top: 3em;
  width: 93%;
}

.secondDiv_home .div2head_home {
  font-size: 2.5em;
  font-weight: 700;
}

.secondDiv_home .div2subhead_home {
  margin: 3px auto;
  width: 96%;
  font-size: 20px;
}

.underline_home {
  font-weight: 700;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(85%, transparent),
    color-stop(30%, rgb(147, 205, 231))
  );
  background-image: #87cefa;
  background-repeat: no-repeat;
  background-size: 100% 250%;
  background-position: 0% 65%;
  transition: background-position 0.5s ease 0s;
}

.underline_home:hover {
  background-position: 0% 100%;
  background-color: #008fb3;
}

.aboutushead_home {
  margin: 50px 0;
}

.aboutus_home {
  display: grid;
  grid-template-columns: 5fr 1fr 4fr;
  padding: 1em;
  width: 95%;
  margin: 0 auto;
  padding-top: 0px;
  /* margin-bottom: 3em; */
}

.aboutus_home .imageCartoon_home {
  width: 100%;
  margin-left: -23px;
  /* margin-bottom: 71px; */
}

.aboutus_home .desc_home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* border: 2px solid black; */
  font-family: "Poppins";
}

.has-search {
  position: absolute;
  top: 100%;
  left: 17%;
  width: 450px;
}

.aboutus_home .desc_home h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

.aboutus_home .desc_home p {
  font-size: 18px;
  max-width: 500px;
  line-height: 1.5;
}

.heading_aboutus_home {
  font-size: 2.5em;
  font-weight: 700;
  width: 90%;
  margin: 70px auto;
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .aboutus_home {
    grid-template-columns: 5fr 0.2fr 3.8fr;
  }
  /* .aboutus_home .imageCartoon_home {
    margin-left: -10px;
  } */
}

.sub_heading_aboutus_home {
  font-size: 20px;
  width: 90%;
  margin: 0 auto;
  margin-top: 1em;
}

.aboutus_home .videoanimation_home {
  justify-content: center;
  position: relative;
}

.aboutus_home .videoanimation_home .videoBtn_home {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid steelblue;
  padding: 0.5em;
  outline: none;
  background-color: #fff;
}

.aboutus_home .videoanimation_home .videoBtn_home i {
  color: steelblue;
}

.categories_home {
  padding: 5em 0;
}

.categories_home .display_home {
  padding: 0 15px;
}

.categories_home .display_home hr {
  margin: 0;
}

.div5_home {
  padding: 0 0 5rem 0;
}

.div5_home .div5head_home {
  font-family: "Times New Roman", Times, serif;
  font-size: 55px;
  font-weight: bolder;
}

.div5_home .div5subhead_home {
  font-size: 18px;
}

.div5_home .div5-grid_home {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 80%;
  margin: 2em auto;
}

.div5_home .div5-grid_home .gridCard_home {
  padding: 1em;
  text-align: center;
}

.div5_home .div5-grid_home .gridCard_home img {
  margin-bottom: 2em;
}

.div5_home .div5-grid_home .gridCard_home p {
  text-align: left;
}

.div6 .div6head_home {
  font-size: 36px;
  color: white;
}

.div6 .div6Btn {
  background-color: #fff;
  outline: none;
  padding: 0.5em;
}

.heading_home_components h1 {
  margin-bottom: 0.8em;
}

.redo_Bookphotographer {
  content: "";
  display: block;
  position: absolute;
  height: 90px;
  width: 50px;
  top: 60%;
  z-index: 1;
  right: -14px;
  -webkit-transform: translateY(-90%);
          transform: translateY(-90%);
  color: #0d98ba;
}

.row_p {
  display: flex;
  flex-wrap: wrap;
  margin-right: -22px;
  margin-left: -127px;
}

.photoStories_home .photoStoriesHead_home {
  font-size: 2em;
  font-weight: bold;
  margin-top: 0em;
}

.testimonials_home {
  background-color: pink;
}

.testimonialsHeading_home {
  font-size: 40px;
}

.booking_home {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.booking_home .cards {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5em 3em;
}

.booking_home .cards h4 {
  margin-bottom: 1em;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}



/* @media only screen and (max-width: 768px), */
@media only screen and (max-width: 524px) and (min-width: 320px){
.booking_home .cards h4 {
  margin-bottom: 1em;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
}
}

.booking_home .cards i {
  color: #007bff;
  font-size: 50px;
  margin-bottom: 1em;
}

.booking_home .cards:nth-of-type(2) {
  background-color: #f7fbfd;
}

.booking_home .cards:nth-of-type(3) {
  background-color: #eff6f8;
}

.booking_home .cards:nth-of-type(4) {
  background-color: #e7f2f7;
}

.topDestinations_home {
  width: 90%;
  margin: 40px auto;
}

.topDestinations_home .img-description {
  padding: 0 1em;
}

.topDestinations_home .explore-destination {
  padding: 5px 16px;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 1em;
  border-radius: 0.2em;
}

.topDestinations_home {
  /* box-shadow: 3px 3px 3px 3px #e4e0e0; */
  box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.1);
  outline: none;
  border-radius: 0.5em;
}
.topDestinations_home .grid .card {
  border-radius: 0.5em;
}

.card-container-explore-home {
  align-items: start;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 470px);
  justify-content: center;
}

/* .topDestinations_home .grid {
display: -ms-grid;
display: grid;
grid-gap: 4em;
-ms-grid-columns: 3fr 3fr 3fr;
grid-template-columns: 3fr 3fr 3fr;
} */

.topDestinations_home .grid .grid-items {
  padding: 1em;
  box-shadow: 2px 10px 24px rgba(0, 0, 0, 0.05);
}

.topDestinations_home .grid .grid-items img {
  width: 100%;
}

.topDestinations_home .grid .card .img-description p {
  font-size: 20px;
}

@media (max-width: 524px) {
  .topDestinations_home .grid .card .img-description p {
    font-size: 15px;
  }
}

.topDestinations_home .grid .card img {
  margin-bottom: 20px;
  border-radius: 0.5em 0.5em 0 0;
}

.blogs_home {
  width: 60%;
  margin: 5em auto;
}

.blogs_home .grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
}

.blogs_home .grid .grid-items {
  position: relative;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  padding: 1em;
}

.blogs_home .grid .grid-items .overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.blogs_home .grid .grid-items .desc {
  position: relative;
  z-index: 10;
}

.blogs_home .grid .grid-items .desc span {
  font-weight: 700;
}

.blogs_home .grid .grid-items .desc .destination {
  text-transform: uppercase;
}

.blogs_home .grid .one {
  background: url("https://images.unsplash.com/photo-1601622256416-d7f757f99eb2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDF8fG5haW5pdGFsfGVufDB8fHx8MTYyNTY0OTAzMA&ixlib=rb-1.2.1&q=80&w=2000");
  background-position: center;
  background-size: cover;
  width: 100%;
}

.blogs_home .grid .two {
  background: url("https://images.unsplash.com/photo-1561731216-c3a4d99437d5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDF8fHRpZ2VyfGVufDB8fHx8MTYyNTY0ODgzNw&ixlib=rb-1.2.1&q=80&w=2000");
  background-position: center;
  background-size: cover;
  width: 100%;
}

.blogs_home .grid .three {
  background: url("https://images.unsplash.com/photo-1590765759804-0b2b579820b3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fG9vdHl8ZW58MHx8fHwxNjI1NjQ4NTA1&ixlib=rb-1.2.1&q=80&w=2000");
  background-position: center;
  background-size: cover;
  width: 100%;
}

.blogs_home .grid .four {
  background: url("https://images.unsplash.com/photo-1516406742981-2b7d67ec4ae8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fG1hbmFsaXxlbnwwfHx8fDE2MjU2NDgwOTM&ixlib=rb-1.2.1&q=80&w=2000");
  background-position: center;
  background-size: cover;
  width: 100%;
}

.blogs_home .grid .five {
  background: url("https://images.unsplash.com/photo-1519046904884-53103b34b206?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDR8fGJlYWNofGVufDB8fHx8MTYyNTU4MTY1NQ&ixlib=rb-1.2.1&q=80&w=2000");
  background-position: center;
  background-size: cover;
  width: 100%;
}

.blogs_home .grid .six {
  background: url("https://images.unsplash.com/photo-1515861461225-1488dfdaf0a8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3M3wwfDF8c2VhcmNofDN8fGF1c3RyYWxpYXxlbnwwfHx8fDE2MjU1ODEzMzI&ixlib=rb-1.2.1&q=80&w=2000");
  background-position: center;
  background-size: cover;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .header_home .description_home {
    padding: 0 3rem;
  }
  .home_heading_content p {
    font-size: 14px;
    width: 100%;
  }
  .home_heading_content {
    color: white;
    position: relative;
    top: 3%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: -37px;
    left: 12%;
    z-index: 10;
    padding-top: 19px;
    padding-bottom: 67px;
    color: #fff;
    text-align: center;
  }
  .res_img {
    width: 170%;
  }
  .header_home .description_home .heading_home {
    font-size: 40px;
  }
  .secondDiv_home,
  .div2subhead_home {
    font-size: 12px;
    width: 95%;
    margin-top: 70px;
  }
  .sub_heading_aboutus_home {
    font-size: 18px;
    width: 93%;
    margin: 1.5em auto;
    margin-top: -51px;
  }
  .heading_aboutus_home {
    font-size: 1.5em;
    font-weight: 700;
    width: 95%;
    margin: 70px auto;
  }
  .aboutus_home {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .aboutus_home .imageCartoon_home {
    /* width: 40%;
      margin-left: 445px;
      margin-top: -65px;
      padding-bottom: 40px; */
    width: 60%;
    display: block;
    margin: auto;
    padding-bottom: 8px;
  }
  .div5_home .div5-grid_home {
    grid-template-columns: 1fr;
  }
  .booking_home {
    grid-template-columns: 1fr;
    width: 90%;
    margin: 30px auto;
  }
  .topDestinations_home {
    width: 90%;
    margin-bottom: 0;
  }

  .topDestinations_home:last-child {
    margin-bottom: 20px;
  }
  .topDestinations_home .grid {
    grid-template-columns: 1fr;
  }
  .blogs_home {
    width: 90%;
  }
  .blogs_home .grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .photoStories_home .photoStoriesHead_home {
    font-size: 40px;
    font-weight: bold;
  }
}

@media screen and (max-width: 767px) {
  .band-top-header_home h1 {
    font-size: 10px;
    font-weight: bold;
    color: #f8f8ff;
  }
  .sb-example-1 .search {
    width: 100%;
    position: relative;
    display: flex;
    margin-top: -4px;
  }
  .home_heading_content {
    color: white;
    position: relative;
    top: -2%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home_heading_content h3 {
    font-size: 1rem;
  }
  .home_heading_content p {
    font-size: 14px;
    width: 100%;
  }
  .d-block {
    display: block !important;
    height: 285px;
  }
  .res_img {
    width: 129% !important;
  }
  .carousel-item {
    height: 50vh;
    min-height: 300px;
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 0px;
    left: 6%;
    z-index: 10;
    padding-top: 19px;
    padding-bottom: 67px;
    color: #fff;
    text-align: center;
  }
  body {
    overflow-x: hidden;
  }
}

@media screen and (width: 375px) {
  .home_heading_content {
    margin-top: 52px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 524px) and (min-width: 320px) {
  .home_heading_content {
    margin-top: 55px;
    margin-bottom: 0px;
  }
  .home_heading_content h3 {
    height: 102px;
    margin-bottom: -31px;
    font-size: 14px;
  }
  .home_heading_content p {
    font-size: 12px;
  }
  /* .res_img {
  width: 260% !important;
  margin-left: -181px;
  height: 320px;
} */
  .secondDiv_home .div2head_home {
    font-size: 18px;
    margin-top: -80px;
  }
  .div2subhead_home p {
    margin: 3px auto;
    width: 96%;
    font-size: 14px;
  }
  .aboutus_home {
    margin-left: -9.6px;
    /* margin-top: -147px; */
  }
  .heading_aboutus_home {
    margin: 56px;
    margin-left: 16px;
    font-size: 18px;
    margin-top: 61px;
  }
  .sub_heading_aboutus_home {
    font-size: 15px;
    width: 100%;
    margin: 70px auto;
    margin-top: -47px;
    margin-left: 14px;
  }
  .aboutus_home .imageCartoon_home {
    width: 78%;
    margin-left: 54px;
    margin-top: -79px;
    /* padding-bottom: 152px; */
  }
  .explore-buttonn {
    padding-top: 48px;
    margin-left: -600px;
    width: 164%;
  }
  .secondDiv_home .div2subhead_home {
    margin: 3px auto;
    width: 96%;
    font-size: 15px;
  }
  .heading_home_components h1 {
    font-size: 18px;
    margin-top: -94px;
    width: 101%;
    margin-left: 0px;
    margin-bottom: -96px;
  }
  .booking_home .cards {
    width: 124%;
    margin-left: -37px;
  }
  .heading_Explore {
    font-size: 22px;
    margin-top: -50px;
    /* margin-bottom: -40px; */
  }
  .topDestinations_home .grid .grid-items p {
    font-size: 14px;
  }
  .booking_home {
    grid-template-columns: 1fr;
    width: 85%;
    margin: 70px auto;
  }

  .topDestinations_home {
    width: 76vw;
  }

  .topDestinations_home .grid {
    grid-template-columns: 1fr;
    width: 102%;
  }
}

.search {
  margin-top: 280px;
}

.sb-example-1 {
  max-width: 100%;
}

input#city_name::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.sb-example-1 .search {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 0px;
  /* margin-left: 9px; */
  /* margin-right: 619px; */
}

.sb-example-1 .searchTerm {
  width: 100%;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  height: 60px;
  border: rgb(255, 255, 255) solid 1px;
  border-right: 1px solid black;
}

.sb-example-1 .searchTerm:focus {
  color: #3d3c3c;
}

.sb-example-1 .searchButton {
  width: 60px;
  height: 60px;
  border: 1px solid rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  text-align: center;
  color: black;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

#home_search {
  display: flex;
  position: relative;
  width: 44em;
  margin: 0 auto;
  box-shadow: 1px 1px 16px 5px #838080;
  border-radius: 5px;
}

#home_search select {
  -webkit-appearance: none;
  appearance: none; /* Remove default arrow */
  background: rgb(255, 255, 255); /* Add custom arrow */
  font-size: 20px;
  padding-left: 24px;
}

#home_search select:hover {
  cursor: pointer;
}

#home_search select option:nth-of-type(1) {
  color: grey;
}

@media (max-width: 900px) {
  #home_search {
    width: 30em;
  }
}
/* @media (max-width: 1100px) {
  #home_search {
    width: 22vw;
  }
} */

@media screen and (max-width: 524px) and (min-width: 320px) {
  #home_search {
    width: 17em;
  }
  .sb-example-1 .searchButton {
    width: 50px;
    height: 46px;
    border: 1px solid rgb(255, 255, 255);
    /* background: transparent; */
    text-align: center;
    /* color: black; */
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }
  
  .sb-example-1 .searchTerm {
    height: 46px;
    background: transparent;
  }
}
/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.containerr {
  max-width: 1170px;
  margin: auto;
}

.roww {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
}

.foooter {
  background-color: skyblue;
  padding: 40px 0;
}

.footer-coll {
  width: 25%;
  padding: 0 15px;
}

.footer-coll h4 {
  font-size: 18px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-coll h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #e91e63;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-coll ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-coll ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: black;
  display: block;
  transition: all 0.3s ease;
}

.footer-coll ul li a:hover {
  color: #2e39d3;
  padding-left: 8px;
}

.footer-coll .social-links ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-coll .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  /* margin:0 10px 10px 0; */
  text-align: center;
  line-height: 37px;
  border-radius: 50%;
  color: black;
  transition: all 0.5s ease;
}

.footer-coll .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

.footer-lastpart {
  background-color: #e7feff;
}

.footer-last {
  margin-bottom: 7px;
}

.footer-lastpart .para_footer {
  margin-bottom: 0 !important;
}

.footer-lastpart .para_footercontent {
  margin-bottom: 0 !important;
}

.row-footer-components {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.para_footer {
  text-align: center;
}

.para_footercontent {
  text-align: center;
}

@media (max-width: 767px) {
  .footer-coll {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 432px) {
  .para_footercontentlast {
    font-size: 11px;
  }
}

/* @media(max-width: 394px){
  .footer-coll{
    width: 100%;
}
} */


#navright{
    display:inline-block;
    position: fixed;
    top: 80vh;
    right: 10px;
    z-index: 1000000000;
    cursor: pointer;
}

.wtsp-icon{
    color: #00e300;
    font-size: 2.5rem;
}
.OurPartners_featured-partner__container__3SR4b {
  width: 100%;
  /* margin: 0 auto; */
  text-align: center;
}

.OurPartners_image-slider__container__3XVjt {
  width: 100%;
  height: 45%;
  overflow: hidden;
  margin: 4% 0;
}
.OurPartners_image-box__2fZRj {
  display: flex;
  height: 100%;
  width: 200%;
  justify-content: space-between;
  align-items: center;
}
.OurPartners_image-box__2fZRj {
  -webkit-animation: OurPartners_imgBox__3oBtc 10s linear infinite;
          animation: OurPartners_imgBox__3oBtc 10s linear infinite;
}
@-webkit-keyframes OurPartners_imgBox__3oBtc {
  0% {
    /* margin-left: 0;*/
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    /* margin-left: -100%;*/
    -webkit-transform: translateX(calc( -100% + 100vw));
            transform: translateX(calc( -100% + 100vw));
   
  }
}
@keyframes OurPartners_imgBox__3oBtc {
  0% {
    /* margin-left: 0;*/
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    /* margin-left: -100%;*/
    -webkit-transform: translateX(calc( -100% + 100vw));
            transform: translateX(calc( -100% + 100vw));
   
  }
}
.OurPartners_image-box__2fZRj .OurPartners_image__1N0ak {
  width: calc(100% - 100px);
  height: 100%;
}
.OurPartners_image-box__2fZRj img {
  height: 60%;
  width: 60%;
  object-fit: cover;
}


@media (max-width: 770px) {
  .OurPartners_image-box__2fZRj {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .OurPartners_image__1N0ak{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
  }

  .OurPartners_image__1N0ak img{
    width: 120px;
    height: auto;
  }

  .OurPartners_image-box__2fZRj {
    -webkit-animation: OurPartners_imgBox__3oBtc 10s linear infinite;
            animation: OurPartners_imgBox__3oBtc 10s linear infinite;
  }
}

@media (max-width: 500px) {
  .OurPartners_featured-partner__container__3SR4b h1{
    font-size: 25px;
  }

  .OurPartners_image-box__2fZRj {
    -webkit-animation: OurPartners_imgBox__3oBtc 15s linear infinite;
            animation: OurPartners_imgBox__3oBtc 15s linear infinite;
  }
}

#navright-backToTop {
  display: inline-block;
  position: fixed;
  top: 90vh;
  right: 10px;
  /* left: 50%;
  transform: translateX(-50%); */
  z-index: 1000000000;
  cursor: pointer;
}

.backToTop-icon,
.backToBottom-icon{
  font-size: 2.5rem;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.backToTop-icon path {
  stroke: #007bff;
}

.how {
  background-color: aliceblue;
  max-width: 82%;
  min-height: 350px;
  margin-top: 33px;
}

.div2subhead_how {
  /* margin: 70px auto; */
  width: 100%;
  font-size: 18px;
  /* margin-bottom: -66px; */
  margin-top: 27px;
  /* text-align: center; */
}

.div2subhead_how p {
  text-align: center;
  font-size: 18px;
}

.howItWorks-section1__container {
  height: 380px;
  display: flex;
  /* align-items: ; */
}

.howItWorks-section1__container .left,
.howItWorks-section1__container .right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.howItWorks-section1__container .left li,
.howItWorks-section1__container .right li {
  display: flex;
  /* align-items: center; */
}

/* .left {
  width: 31%;
  margin-left: 49px;
  margin: 85px;
}

.right {
  width: 34%;
  float: right;
  margin-top: -380px;
  margin-right: 55px;
} */

li {
  list-style: none;
}

.img_icon {
  width: 50px;
  /* height: 46px; */
  height: 50px;
  margin-right: 1em;
  /* margin: -78px; */
  /* margin-top: 24px; */
}

/* .left li:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 28px;
  width: 2px;
  height: 150%;
} */

/*search bar styling   */

.sb-example-1_how {
  max-width: 100%;
}

.sb-example-1_how .search_how {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 0px;
  margin-left: 513px;
  padding-bottom: 16px;
  /* margin-right: 619px; */
}

.sb-example-1_how .searchTerm_how {
  width: 20%;
  border: 3px;
  border-right: none;
  padding: 24px;
  border-radius: 5px 0 0 5px;
  outline: none;
  height: 50px;
  background: transparent;
  border: black solid 1px;
}

.sb-example-1_how .searchTerm_how:focus {
  color: #0f0e0e;
}

.sb-example-1_how .searchButton_how {
  width: 40px;
  height: 50px;
  border: 1px solid black;
  background: transparent;
  text-align: center;
  color: black;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.Top-band_home {
  text-align: center;
  background-color: #00008b;
  position: relative;
  z-index: 1;
  width: 100%;
}

.search_how {
  width: 100%;
  margin-left: 0;
  padding-bottom: 26px;
  padding-top: 11px;
}

.search_heading {
  /* align-items: center;
  margin-left: 426px; */
  width: 100%;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.how-h1 {
  margin: 15px;
  font-weight: 700;
}
@media (max-width: 1230px) {
  .search_heading {
    padding-top: 68px;
    width: 75%;
    margin: auto;
    text-align: center;
  }
  .search_how {
    margin: auto;
  }
}

@media (max-width: 840px) {
  .how {
    max-width: 85%;
    margin-left: 51px;
  }
  .how-h1 {
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bolder;
  }
  .div2subhead_how p {
    text-align: center;
    margin: 30px;
    margin-bottom: 18px;
  }
  .how {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 908px;
  }
  /* .img_icon {
    height: 42px;
  } */
  .left {
    width: 70%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0;
    margin: 6px;
  }
  li p {
    font-size: 15px;
    /* letter-spacing: 1.4px; */
  }
  li h4 {
    font-size: 18px;
    line-height: 1.2;
    /* letter-spacing: 3px; */
    font-weight: bolder;
    /* padding: 7px; */
  }
  .card_content {
    text-align: center;
  }
  .right {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin: 6px;
  }
  .search_heading {
    padding-top: 68px;
    width: 75%;
    margin: auto;
    text-align: center;
  }
  .search_how {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .right {
    display: block;
  }
  .card_content {
    width: 50vw !important;
  }
  .search_heading h3 {
    font-size: large;
  }
  .searchTerm::-webkit-input-placeholder {
    font-size: 10px !important;
  }
  .searchTerm::placeholder {
    font-size: 10px !important;
  }
  .how {
    margin-left: 28px;
    padding: 15px;
  }
}
#portfolio-header {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1em auto;
}
.div2subhead_portfolio {
  width: 80%;
  margin: 20px 70px ;
  font-size: 18px;
  text-align: justify;
}
.media-type-switch {
  width: 100%;
  padding: 10px;
}

.media-type-switch label {
  font-size: 20px;
  margin-right: 10px;
  
  align-items: center;
}

.media-type-switch input[type="radio"] {
  margin-right: 5px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5); /* Adjust the scale factor as needed */
}


/* .div2subhead_portfolio p h1 {
  display: inline;
  font-size: 20px;
} */
.search_heading_packages {
  align-items: center;
  margin-left: 426px;
  /* padding-top: -36px; */
  margin-top: -35px;
}


.newheader {
  text-align: center;
  text-transform: uppercase;
  padding: 32px;
  background-color: #0a0a23;
  color: #fff;
  border-bottom: 4px solid #fdb347;
}

.newgallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px 10px;
}

.newgallery img {
  width: 100%;
  max-width: 420px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.newgallery::after {
  content: "";
  width: 420px;
}



.hovereffects {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  margin-bottom: 10px;
}

.hovereffects .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
}

.hovereffects img {
  display: block;
  position: relative;
  transition: all 0.4s linear;
}

.hovereffects h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  transition: all 0.2s ease-in-out;
  padding: 10px;
}

.hovereffects a.info {
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.2s ease-in-out;
  margin: 50px 0 0;
  padding: 7px 14px;
}

.hovereffects a.info:hover {
  box-shadow: 0 0 5px #fff;
}

.hovereffects:hover h2,
.hovereffects:hover a.info {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.hovereffects:hover a.info {
  transition-delay: 0.2s;
}
.text-center.m-4.heading_portfolio-main {
  margin: 20px;
  padding: 50px;
  font-size: 3em;
}

a.btn_portfolio {
  -webkit-text-decoration: NONE;
          text-decoration: NONE;
}
.ending_Portfolio_text {
  font-weight: 900;
}
/* Masonry styling */

.search_portfolio {
  width: 36vw;
  margin: auto;
  text-align: center;
  margin-bottom: 21px;
}

.search_portfolio h3 {
  width: 182% !important;
  margin-inline: -43% !important;
  padding-bottom: 4% !important;
  text-align: center !important;
}

.gal-container {
  padding: 5px;
}
.gal-item {
  overflow: hidden;
  padding: 15px;
}
.gal-item .box {
  height: 350px;
  overflow: hidden;
}
.box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.gal-item a:focus {
  outline: none;
}
.gal-item a:after {
  content: "\e003";
  font-family: "Glyphicons Halflings";
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  right: 3px;
  left: 3px;
  top: 3px;
  bottom: 3px;
  text-align: center;
  line-height: 350px;
  font-size: 30px;
  color: #fff;
  transition: all 0.5s ease-in-out 0s;
}
.gal-item a:hover:after {
  opacity: 1;
}
.modal-open .gal-container .modal {
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-open .gal-item .modal-body {
  padding: 0px;
}
.modal-open .gal-item button.close {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #000;
  opacity: 1;
  color: #fff;
  z-index: 999;
  right: -12px;
  top: -12px;
  border-radius: 50%;
  font-size: 15px;
  border: 2px solid #fff;
  line-height: 25px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35);
}

@media (min-width: 768px) {
  .gal-container .modal-dialog {
    width: 55%;
    margin: 50 auto;
  }
}

@media screen and (max-width: 1024px) and (min-width: 580px) {
  .col-md-7 {
    max-width: 99.333333% !important;
  }

  .col-md-5 {
    max-width: 99.666667% !important;
  }

  .col-md-4 {
    max-width: 99.333333% !important;
  }

  .col-md-8 {
    max-width: 99.666667% !important;
  }
}

@media (mix-width: 768px) {
  .gal-container .modal-content {
    height: 250px;
  }

  /* .search_portfolio {
    width: 39vw;
    margin: auto;
    margin-bottom: 21px;
    margin-left: 498px;
  } */
}

@media (max-width: 767px) {
  .text-center.m-4.heading_portfolio-main {
    font-size: 1em;
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 500px) {
  .search_portfolio h3 {
    font-size: x-large;
  }
}

@media (max-width: 416px) {
  .search_portfolio h3 {
    font-size: larger;
  }
}

* {
  margin: 0;
  padding: 0;
}
.main_container_pf {
  width: 93%;
  margin: auto;
}

.container_pf {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.container_pf .img_pf1 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf2 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf4 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf3 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf5 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf5 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf6 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf6 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf8 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf8 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf7 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf7 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf9 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf9 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf10 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf10 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 990px) {
  .container_pf {
    flex-direction: column;
    align-items: center;
  }

  .container_pf .img_pf {
    margin: 15px 0;
    width: 89vw;
    /* height: auto; */
  }
}

@media screen and (max-width: 560px) {
  .div2subhead_portfolio {
    font-size: 14px;
    margin: 40px auto;
  }

  .container_pf {
    margin: 0;
  }
  .container_pf .img_pf {
    height: auto;
    margin: 8px 0;
  }

  .container_pf .img_pf img {
    height: auto;
  }
}


.grid--container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(8, 1fr);
}
.grid--image {
  /* background-image: url("https://images.unsplash.com/photo-1494976388531-d1058494cdd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"); */
  background-size: cover;
  background-position: center;
  grid-column: 1 / 7;
  grid-row: 1 / 7;
}
.grid--content {
  background-color: rgba(255,255,255,0.8);
 height: -webkit-fit-content;
 height: -moz-fit-content;
 height: fit-content;

  grid-column: 6 / -1;
  grid-row: 4 / -1;
}
.card--title {
  font-family: 'Blinker', sans-serif;
}
/* button {
  border: 1px solid black;
  
  &:hover {
    background: #000;
    color: white;
    box-shadow: 0 0 32px rgba(0,0,0,0.2);
  }
} */

@media (max-width: 1000px) {
  .grid-container {
    height: 100%;
  }
  .grid--content {
    background-color: rgba(255,255,255,0.9);
    /* grid-column: 5 / span 6; */
  }
}
@media (max-width: 768px) {
  .grid--image {
    grid-column: 1 / -1
  }
 
  .grid--content {
    background-color: rgba(255,255,255,0.9);
    grid-column: 2 / span 8;
    /* grid-row: 6 / -1; */
    min-height: 100%;
  }
}
@media (max-width: 500px) {
  .grid--container {
    box-shadow: 0 0 32px rgba(0,0,0,0.5);
  }
  .grid--image {
    grid-column: 1 / -1;
    grid-row: 1 / span 4;
  }
  .grid--content {
    background-color: white;
    grid-column: 1 / -1;
    /* grid-row: 5 / -1; */
    min-height: 100%;
  }
}


.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}



.loader-text {
  font-size: 24px;
  margin-top: 16px;
  color: #36D7B7;
}
#packages {
  width: 60%;
  height: 60px;
  border: none;
  outline: none;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-left: 20%;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 400;
  scroll-behavior: smooth;
}

#first_package {
  -webkit-animation-name: packageTravel;
          animation-name: packageTravel;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
}

#first_package:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes packageTravel {
  0% {
    margin-top: -400px;
    transition: ease;
  }
  100% {
    margin-top: 0px;
    transition: ease;
  }
}

@keyframes packageTravel {
  0% {
    margin-top: -400px;
    transition: ease;
  }
  100% {
    margin-top: 0px;
    transition: ease;
  }
}

#packages_link {
  display: block;
  padding: 10px;
}

#packages::-webkit-scrollbar {
  display: none;
}

.packages_code {
  padding-top: 38px;
}

.list-name_two {
  margin-top: 26px;
  text-align: center;
}

.color:active {
  text-decoration: none;
  color: skyblue;
}

ul#menu li {
  display: inline;
  margin: 35px;
}

.hr_two {
  width: 100%;
  margin-left: 0;
}

.heading {
  font-size: 18px;
  text-align: center;
  background-color: #007bff;
  color: #fff;
  padding-top: 12px;
}

.link_package {
  color: black;
}

.list-name {
  margin: 15px;
  font-size: 18px;
  text-align: center;
}

.heading_form {
  font-size: 3em;
  margin: -1px;
  padding-left: -9px;
  margin-top: -11px;
  text-align: center;
}

.form_button {
  width: 104px;
  padding: 9px;
  background-color: #007bff;
  color: white;
}

.submit {
  margin: 24px;
  margin-inline: 585px;
}

.sub_form {
  margin: 33px;
  margin-inline: 614px;
  width: 60px;
}

.faq {
  padding: 29px;
  margin-left: -20px;
}

.callback {
  margin: 24px;
  margin-left: 27px;
  width: 96%;
}

.textarea {
  padding-left: 28px;
  width: 97%;
  padding-top: 13px;
}

.form_col {
  padding-right: 32px;
  padding-left: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -21px;
  padding: 11px;
}

#search_heading_packages {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.searchdes {
  /* margin-left: 530px; */
  margin-left: 15%;
  margin-top: 21px;
  width: 70%;
  padding-bottom: 13px;
}

.categories {
  width: 103%;
  margin-left: 51px;
}

.packages_code {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.line {
  width: 100%;
  margin-left: 0px;
}

.premium {
  box-shadow: 0 -9px 50px rgb(0 0 0 / 10%);
  width: 28%;
  margin-top: -26px;
  height: 540px;
  background: #fff;
  width: calc(29% - 15px);
  padding: 0px 30px;
  position: relative;
  box-shadow: 0 -9px 50px rgb(0 0 0 / 10%);
  height: 510px;
}

.premiumheadng {
  width: 305px;
}

.packageform {
  padding-right: 32px;
  padding-left: 20px;
}

.wrapper {
  max-width: 1090px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.heading {
  font-size: 18px;
  text-align: center;
  background-color: #007bff;
  width: 100%;
  /* margin-left: -30px; */
  height: 52px;
}

.wrapper .table {
  background: #fff;
  width: calc(30% - 15px);
  /* height: fit-content; */
  padding: 0px 30px;
  position: relative;
  box-shadow: 0 -9px 50px rgb(0 0 0 / 10%);
  height: 540px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.wrapper .table::-webkit-scrollbar {
  width: 4px;
}

.table .text {
  text-align: center;
  margin: 40px;
}

.table .package-name::before {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 25px;
  font-weight: 500;
  background: #fff;
  padding: 0 15px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.table .btn {
  width: 100%;
  display: flex;
  margin-top: 35px;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.table .btn button {
  width: 104%;
  height: 70px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #007bff;
  -webkit-transform: translateX(-26%);
          transform: translateX(-26%);
  margin-left: 50px;
}

@media (max-width: 1020px) {
  .wrapper .table {
    width: calc(50% - 20px);
    margin-bottom: 40px;
  }
}

@media (max-width: 698px) and (min-width: 560px) {
  /* .wrapper .table {
    width: 91%;
  } */
  .search_heading_packages {
    align-items: center;
    margin-left: 90px;
    margin-top: -35px;
  }
  .textarea {
    padding-left: 9px;
    width: 97%;
    padding-top: 13px;
  }
  .searchdes {
    margin-left: 46px;
    margin-top: 21px;
    width: 76%;
    padding-bottom: 13px;
  }
  .categories {
    width: 116%;
    margin-left: -62%;
  }
}

.table .ribbon {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

.table .ribbon::before,
.table .ribbon::after {
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #e4d00a;
  border-top-color: transparent;
  border-left-color: transparent;
}

.table .ribbon::before {
  top: 0px;
  right: 15px;
}

.table .ribbon::after {
  bottom: 15px;
  left: 0px;
}

.table .ribbon div {
  position: absolute;
  top: 30px;
  right: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 200px;
  background: #e4d00a;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  font-size: 17px;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}

.row_1 {
  font-size: 18px;
}

.row_2 {
  font-size: 18px;
}

.row_3 {
  font-size: 18px;
}

.row_4 {
  font-size: 18px;
}

.search_heading_packages {
  align-items: center;
  margin-left: 426px;
  margin-top: -35px;
}

@media (max-width: 560px) and (min-width: 320px) {
  .wrapper .table {
    width: 108%;
  }
  .line {
    width: 100%;
  }
  .hr_two {
    width: 100%;
  }
  .packageform {
    padding-right: -13px;
    padding-left: -17px;
  }
  .textarea {
    padding-left: 9px;
    width: 97%;
    padding-top: 13px;
  }
  .search_heading_packages {
    align-items: center;
    margin-left: 90px;
    margin-top: -35px;
  }
  .callback {
    margin: 24px;
    margin-left: -4px;
    width: 106%;
  }
  .categories {
    width: 120%;
    margin-left: -60%;
  }
}

@media (max-width: 768px) and (min-width: 580px) {
  .categories {
    width: 116%;
    margin-left: -61%;
  }
}

@media (max-width: 1024px) {
  .callback {
    margin: 24px;
    margin-left: 13px;
    width: 94%;
  }
  /* .packages_code {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108%;
} */
  .textarea {
    padding-left: -12px;
    width: 99%;
    padding-top: 13px;
    margin-inline: 1%;
  }
}

.package_wrapper{
  padding: 1rem;
}
  
.wrapper.packacge_card_wrapper{
  margin: 0;
  padding: 1rem;
}

.heading_faqs {
  text-align: center;
  text-transform: capitalize;
  font-size: 2.5em;
  font-weight: 700;
  letter-spacing: 5;
  word-spacing: 5px;
  margin-bottom: 50px;
  color: #2c3148;
}
.main-div-faq {
  margin: 10px;
  width: 90%;
  margin-left: 70px;
}
.main-heading_package {
  width: 50%;
  display: flex;
  margin-top: -30px;
  padding: 20px 0 20px 20px;
  background: #f8f8ff;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}

.main-heading_package:hover {
  /* background-color: #d0e2f6; */
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}
.main-heading_package:first-child {
  margin-top: 0;
}
.content {
  display: none;
}
.main-heading_package h3 {
  margin-left: 30px;
  word-spacing: 1px;
  /* letter-spacing: 1px; */
  font-weight: 500;
  font-size: 20px;
}

.main-heading_package p {
  margin: 1rem;
}

.answers {
  padding: 19px;
  font-size: 14px;
  /* word-spacing: 2px; */
  font-weight: 400;
  line-height: 1.7;
  background: rgba(203, 224, 231, 0.959);
  color: black;
  /* text-transform: capitalize; */
}

.Show-hide-faq > i:before{
  font-size: 1.5rem;
}

.loadmore-faq{
  margin-top: 12px !important;
}

.search_heading.search_heading-packages{
  padding: 0;
}
@media screen and (max-width: 524px) and (min-width: 320px) {
  /* .main-div-faq {
    margin: 9px;
    margin-top: -21px;
  } */
  #load-more {
    margin-left: 55px;
  }
}
@media (max-width: 1024px) and (min-width: 320px) {
  .content h3 {
    padding: 0 !important;
  }

  .main-heading_package{
    width: 100%;
  }
  .main-heading_package h3 {
    margin-left: 0;
    margin: auto;
  }

  .faq_components {
    width: 80vw !important;
    margin: auto;
  }
  .answers {
    width: 80vw !important;
  }

  .main-div-faq{
    margin-left: 0;
    margin: auto;
  }
}

.Requestbooking_css {
    position: relative;
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* max-width: 200vh;
  max-height: 200vh; */
    padding: 20px;
}

.form h1 {
    color: #fff;
    font-weight: 700;
}

.Requestbooking_css input {
    border-radius: 25px;
    padding: 20px;
    width: 70%;
    outline: none;
}

.Requestbooking_css:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(2, 0, 36);
    background: linear-gradient( 90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    -webkit-transform: scaleX(1.5);
            transform: scaleX(1.5);
}

.Requestbooking_css .form {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 900px;
    text-align: center;
}

.Requestbooking_css .form p {
    color: #fff;
    font-size: 16px;
    position: relative;
    top: -30px;
}

input#cdate {
    border-radius: 25px;
    padding: 20px;
    width: 70%;
    outline: none;
}

.next_btn_requestbooking {
    background-color: #87ceeb;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    padding: 15px 32px;
    margin: 30px;
}

@media (max-width: 767px) {
    .Requestbooking_css .form p {
        font-size: 12px;
    }
    .form h1 {
        color: #fff;
        font-weight: 700;
        font-size: 20px;
    }
    .Requestbooking_css {
        background: linear-gradient( 90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
    }
}
.img_mainteam {
  border-radius: 50%;
  height: 8em;
  width: 8em;
  margin-left: 37%;
}
.name {
  display: inline-flex;
  margin-left: 20px;
  font-size: 24px;
  text-decoration: none;
}
.team {
  width: 50%;
  display: inline;
  padding: 20px;
  padding-top: 40px;
}
.img_team {
  border-radius: 50%;
  width: 78%;
  margin-left: 15%;

  height: 8em;
  width: 8em;
}
.img_team_dev {
  border-radius: 50%;
  width: 78%;
  margin-left: 633px;
  height: 8em;
  width: 8em;
  margin-top: 10px;
}
.work_main-dev {
  margin-left: 783px;
  margin-top: -51px;
}
.main_team {
  margin-left: 58px;
}

.work_main {
  display: block;
  margin-left: 560px;
  margin-top: -46px;
  font-size: 18px;
}

.work-left {
  margin-left: 342px;
  margin-top: -47px;
}

.work-right {
  margin-left: 245px;
  margin-top: -42px;
}

.work {
  margin-left: 247px;
  margin-top: -48px;
  font-size: 18px;
}
.linkedin_main {
  display: block;
  margin-left: 618px;
  margin-top: 3px;
}
.linkedin {
  margin-left: 252px;
  margin-top: -5px;
}
.our_team {
  width: 99%;
  margin: -41px auto;
  margin-inline: -22px;
}
/* img.aboutus_graphics {
  margin-top: -280px;
} */
.aboutus_heading {
  margin: 2rem 0 0 5px;
  padding: 10px;
}

.aboutus_heading > h1{
  font-weight: 600;
}

.aboutus_para {
  margin: 4px;
  padding: 10px;
  line-height: 1.2;
}

/* .aboutus_component .about_img1 {
  width: 85vw;
}
.aboutus_component .about_img2 {
  width: 90vw;
} */

/* ************************************************ New codde tetsing.... ************************************** */
/* 
.aboutus_component_para{
  margin: 3rem !important;
}

.what_is_fodrix{
  display: flex;
  gap: 2%;
}

.what_is_fodrix-wrapper{
  width: 60%;
}

.Why_choose_Fodrix{
  display: flex;
  flex-direction: row-reverse;
  gap: 2%;
}

.Why_choose_Fodrix-wrapper{
  width: 60%;
}

.How_Fodrix_started{
  display: flex;
  gap: 2%;
}

.How_Fodrix_started-wrapper{
  width: 60%;
}

.Why_choose_Fodrix-wrapper p, .what_is_fodrix p, .How_Fodrix_started p{
  text-align: justify;
}

.img1, .img2, .img3{
  height: 23rem;
  display: flex;
  overflow: hidden;
  align-items: center;
} */

.aboutus_component_para{
  margin-top: 3rem !important;
}

.aboutus_container{
  display: flex;
  grid-gap: 2%;
  gap: 2%;
}

.aboutus_container-wrapper{
  width: 60%;
}

.aboutus_container:nth-child(2){
  flex-direction: row-reverse;
}

.aboutus_container-wrapper p{
  text-align: justify;
  font-size: 20px;
}

@media only screen and (max-width: 524px) and (min-width: 320px){
  .aboutus_container-wrapper p{
    text-align: justify;
    font-size: 15px;
  }
}


.aboutus-img{
  height: 23rem;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.aboutus-img > img{
  width: 100%;
}

/* ************************************************************************************************************* */
/* ============ new css ============== */

.services {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(6, 6rem);
  grid-row-gap: 4rem;
}

.service {
  width: 100%;
  margin-bottom: 1rem;
}

.service:nth-child(1) {
  grid-column: 4 / 7;
  grid-row: 1 / 3;
}

.service:nth-child(2) {
  grid-column: 3 / 6;
  grid-row: 3 / 5;
}

.service:nth-child(3) {
  grid-column: 4 / 7;
  grid-row: 5 / -1;
}

.service:nth-child(4) {
  grid-column: 11 / 14;
  grid-row: 1 / 3;
}

.service:nth-child(5) {
  grid-column: 12 / 15;
  grid-row: 3 / 5;
}

.service:nth-child(6) {
  grid-column: 11 / 14;
  grid-row: 5 / -1;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.service-header i {
  font-size: 3.2rem;
  color: #4b4b4b;
  margin-right: 1.3rem;
}

.service-header h3 {
  font-family: "Baloo Da 2", serif;
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.service-text {
  font-family: "Josefin Slab", serif;
  font-size: 1.6rem;
  text-align: justify;
  font-weight: 600;
}

/* ======= */
/* ================ new team code ================== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif; */
}

.wrapper {
  margin-top: 60px;
  text-align: center;
}

.wrapper h1 {
  font-size: 48px;
  color: black;
  margin-bottom: 25px;
}

.our_team {
  width: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.our_team .team_member {
  /* width: 250px; */
  margin: 5px;
  background: transparent;
  padding: 20px 10px;
}

.our_team .team_member .member_img {
  background: #e9e5fa;
  max-width: 190px;
  width: 100%;
  height: 190px;
  margin: 0 auto;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.our_team .team_member .member_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.our_team .team_member h3 {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 2px;
  margin: 15px 0 0px;
  margin-bottom: 7px;
}

.our_team .team_member span {
  font-size: 14px;
}

.our_team .team_member1 {
  width: 80vw;
}

.our_team .team_member2 {
  width: 46vw;
}
.our_team .team_member3 {
  width: 45vw;
}
.our_team .team_member4 {
  width: 46vw;
}
.our_team .team_member5 {
  width: 45vw;
}
.our_team .team_member6 {
  margin-bottom: 5vh;
}

.our_team .team_member p {
  margin-top: 20px;
  font-size: 17px;
  line-height: 20px;
}

.our_team .team_member .member_img .social_media {
  position: absolute;
  top: 5px;
  left: 5px;
  background: rgba(0, 0, 0, 0.65);
  width: 95%;
  height: 95%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.5s ease;
}

.our_team .team_member .member_img .social_media .item {
  margin: 0 10px;
}

.our_team .team_member .member_img .social_media .fab {
  color: #8c7ae6;
  font-size: 20px;
}

.our_team .team_member .member_img:hover .social_media {
  -webkit-transform: scale(1);
          transform: scale(1);
}

@media screen and (max-width:1024px){
  .aboutus_component_para{
    margin: 2rem !important;
  }

  .aboutus_container-wrapper{
    width: 170%;
  }

  .aboutus_heading {
    padding-bottom: 0;
  }

  .aboutus_heading > h1{
    font-size: 2rem;
  }

}

@media (max-width: 540px) {
  .aboutus_para {
    font-size: 14px;
  }

  .aboutus_component_para{
    margin: 1rem !important;
  }

  .aboutus_container, .aboutus_container:nth-child(2){
    flex-direction: column;
  }

  .aboutus_container-wrapper{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .aboutus_heading > h1{
    font-size: 24px;
  }

  .aboutus-img{
    height: 10rem;
  }
}

/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  } */

.wrapper {
  display: flex;
  margin-top: 60px;
  text-align: center;
}

.wrapper h1 {
  font-size: 48px;
  color: black;
  margin-bottom: 2em;
}

@media (max-width: 540px) {
  .wrapper h1 {
    font-size: 25px;
    font-weight: 600;
    color: black;
    margin-bottom: 2em;
  }
}

.our_team {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.our_team .team_member {
  /* width: 250px; */
  margin: 1px;
  background: transparent;
  padding: 20px 10px;
  width: 35vw;
}

.our_team .team_member .member_img {
  background: #e9e5fa;
  max-width: 190px;
  width: 100%;
  height: 190px;
  margin: 0 auto;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.our_team .team_member .member_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.our_team .team_member h3 {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 2px;
  margin: 15px 0 0px;
  margin-bottom: 7px;
}

@media (max-width: 540px) {
  .our_team .team_member h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.our_team .team_member span {
  font-size: 16px;
}

/* .our_team .team_member1 {
  width: 30vw;
}

.our_team .team_member2 {
  width: 30vw;
}
.our_team .team_member3 {
  width: 30vw;
}
.our_team .team_member4 {
  width: 30vw;
}
.our_team .team_member5 {
  width: 30vw;
}

.our_team .team_member6 {
  margin-bottom: 5vh;
} */

.our_team .team_member p {
  margin-top: 20px;
  font-size: 17px;
  line-height: 20px;
}

.our_team .team_member .member_img .social_media {
  position: absolute;
  top: 5px;
  left: 5px;
  background: rgba(0, 0, 0, 0.65);
  width: 95%;
  height: 95%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.5s ease;
}

.our_team .team_member .member_img .social_media .item {
  margin: 0 10px;
}

.our_team .team_member .member_img .social_media .fab {
  color: #8c7ae6;
  font-size: 20px;
}

.our_team .team_member .member_img:hover .social_media {
  -webkit-transform: scale(1);
          transform: scale(1);
}

@media screen and (max-width: 565px) {
  .our_team .team_member {
    width: 100%;
  }
  /* .our_team .team_member2 {
    width: 44vw;
  }
  .our_team .team_member3 {
    width: 44vw;
  }
  .our_team .team_member4 {
    width: 44vw;
  }
  .our_team .team_member5 {
    width: 44vw;
  }
  .our_team .team_member6 {
    width: 63vw;
  } */

  /* .our_team .team_member h3 {
    font-size: 17px;
  }

  .member_img1 {
    width: 96% !important;
    height: 166px !important;
  }

  .member_img2,
  .member_img3,
  .member_img4,
  .member_img5 {
    width: 96% !important;
    height: 166px !important;
  }

  .member_img6 {
    width: 81% !important;
    height: 156px !important;
  } */
}

/* @media screen and (max-width: 400px) {
  .member_img1 {
    width: 101% !important;
    height: 153px !important;
  }

  .member_img2,
  .member_img3,
  .member_img4,
  .member_img5 {
    width: 101% !important;
    height: 153px !important;
  }
}

@media screen and (max-width: 352px) {
  .member_img1 {
    width: 58% !important;
    height: 155px !important;
  }

  .member_img2,
  .member_img3,
  .member_img4,
  .member_img5 {
    width: 101% !important;
    height: 142px !important;
  }
} */

.heading_faqs {
  text-align: center;
  text-transform: capitalize;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 5;
  word-spacing: 5px;
  margin-bottom: 50px;
  color: #2c3148;
  margin-top: 20px;
}


@media (max-width: 540px) {
  .heading_faqs {
    font-size: 24px;
    font-weight: 600;
  }
}

.main-div-faq {
  margin: 10px;
  width: 90%;
  margin-left: 70px;
}
.main-heading_faq2 {
  display: flex;
  margin-top: -30px;
  padding: 20px 0 20px 20px;
  background: #f8f8ff;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}

.main-heading_faq2:hover {
  background-color: #d0e2f6;
  cursor: pointer;
}
.main-heading_faq2:first-child {
  margin-top: 0;
}
.content {
  display: none;
}
.main-heading_faq2 h3 {
  margin-left: 30px;
  word-spacing: 1px;
  /* letter-spacing: 1px; */
  font-weight: 500;
  font-size: 18px;
}

.main-heading_faq2 p {
  width: 3vh;
  cursor: pointer;
  padding: 5px;
}

.answers {
  padding: 19px;
  font-size: 18px;
  /* word-spacing: 2px; */
  padding-left: 50px;
  font-weight: 400;
  line-height: 1.7;
  background: rgba(203, 224, 231, 0.959);
  color: black;
  /* text-transform: capitalize; */
}
.Show-hide-faq {
  font-size: 800;
}

.loadmore-faq {
  margin-top: 12px !important;
}

@media screen and (max-width: 524px) and (min-width: 320px) {
  /* .main-div-faq {
    margin: 9px;
    margin-top: -21px;
  } */
  #load-more {
    margin-left: 55px;
  }
}
@media (max-width: 767px) and (min-width: 320px) {
  .content h3 {
    padding: 0 !important;
  }

  .main-heading_faq2 h3 {
    margin-left: 0;
    margin: auto;
  }

  .faq_components {
    width: 80vw !important;
    margin: auto;
  }
  .answers {
    width: 80vw !important;
    padding-left: 20px;
  }

  .main-div-faq {
    margin-left: 0;
    margin: auto;
  }
}
/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.containerr {
  max-width: 1170px;
  margin: auto;
}

.roww {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
}

.foooter {
  background-color: skyblue;
  padding: 20px 0px;
}

.footer-coll {
  width: 25%;
  padding: 0 15px;
}

.footer-coll h4 {
  font-size: 20px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-coll h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #e91e63;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-coll ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-coll ul li a {
  font-size: 18px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: black;
  display: block;
  transition: all 0.3s ease;
}

@media (max-width: 524px) {
  .footer-coll ul li a {
    font-size: 16px;
  }
}

.footer-coll ul li a:hover {
  color: #2e39d3;
  padding-left: 8px;
}

.footer-coll .social-links ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-coll .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  /* margin:0 10px 10px 0; */
  text-align: center;
  line-height: 37px;
  border-radius: 50%;
  color: black;
  transition: all 0.5s ease;
}

.footer-coll .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

.footer-lastpart {
  background-color: #e7feff;
}

.footer-last1 {
  margin: 0.5em 0;
}

.footer-lastpart .para_footer {
  margin-bottom: 0 !important;
}

.footer-lastpart .para_footercontent {
  margin-bottom: 0 !important;
}

.row-footer-components {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* line-height: 1; */
  padding: 0.5em;
}

.para_footer {
  text-align: center;
  font-size: 18px;
}

.para_footercontent {
  text-align: center;
  font-size: 18px;
}

.para_footercontentlast {
  margin: 0.1em 0 0 0;
}

.para_footercontentlast a {
  font-size: 18px;
  color: #000;
}

.lowerFooter-contactDetails{
  font-size: 18px;
}
@media (max-width: 524px) {
  .para_footer,
  .para_footercontent,
  .para_footercontentlast a,
  .lowerFooter-contactDetails {
    font-size: 15px;
  }

  .footer-last1 img {
    height: 40px;
  }
}

#address_ft {
  margin-top: 3vh;
  width: 100%;
}

#add_ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#add_ul li {
  margin-bottom: 3vh;
  font-size: 18px;
}

@media (max-width: 767px) {
  .footer-coll {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 524px) {
  #address_ft #add_ul li {
    font-size: 15px;
  }
}

@media (max-width: 432px) {
  .para_footercontentlast {
    font-size: 11px;
  }
}

/* @media(max-width: 394px){
  .footer-coll{
    width: 100%;
}
} */

.OfferAlert_offerAlert-container__V3X1O {
  width: 100%;
  height: 10em;
  margin: 0 auto;
  background-color: #007bff;
  text-align: center;
}

.OfferAlert_offerAlert__inner__3gzPW {
  margin: 0;
  font-size: 1em;
  background-color: #007bff;
  color: #fff;
  padding: 0.5em 0;
}

@media (max-width: 710px) {
  .OfferAlert_offerAlert__inner__3gzPW {
    font-size: 0.9em;
  }
}

@media (max-width: 450px) {
  .OfferAlert_offerAlert__inner__3gzPW {
    font-size: 0.8em;
  }
}




/* admin_view_partners.css */

.partner-list {
    margin-top: 20px;
  }
  
  .partner-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .partner-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .partner-details {
    margin-bottom: 5px;
  }
  
  .partner-label {
    font-weight: bold;
  }
  
  .partner-label::after {
    content: ":";
  }
  
  .partner-message {
    margin-top: 5px;
  }
  
*{

    margin: 0;

    padding: 0;

    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.admin-section{

    display: flex;

    justify-content: center;

    align-items: center;

    min-height: 100vh;

    width: 100%;

    

    background: url("https://i.postimg.cc/SxB07GjW/beach-bg.jpg")no-repeat;

    background-position: center;

    background-size:cover; 

}

.form-box-login{

    position: relative;

    width: 320px;

    height: 350px;

    background: transparent;

    border:none;

    border-radius: 20px;

    -webkit-backdrop-filter:blur(15px) brightness(80%);

            backdrop-filter:blur(15px) brightness(80%);

    

    display: flex;

    justify-content: center;

    align-items: center;

}

.login_head{

    font-size: 2em;

    color:#fff;

    text-align: center;

}

.inputbox{

    position: relative;

    margin: 30px 0;

    width: 270px;

    border-bottom: 2px solid #fff;

}

.inputbox label{

    position: absolute;

    top:50%;

    left: 5px;

    -webkit-transform: translateY(-50%);

            transform: translateY(-50%);

    color: #fff;

    font-size: 1em;

    pointer-events:none;

    transition: .5s;

}

/* animations: start */

input:focus ~label,

input:valid ~label{

    top: -5px;

}

/* animation:end */

.inputbox input{

    width: 100%;

    height: 50px;

    background: transparent;

    border: none;

    outline: none;

    font-size: 1em;

    padding: 0 35px 0 5px;

    color: #fff;

}

.inputbox ion-icon{

    position: absolute;

    right:8px;

    color: #fff;

    font-size: 1.2em;

    top:20px;

}



.admin_button{

    width: 100%;

    height: 40px;

    border-radius: 40px;

    background-color: #fff;

    border:none;
    color: black;
    outline: none;

    cursor: pointer;

    font-size: 1em;

    font-weight: 600;

}


/* Responsiveness:Start */
@media screen and (max-width:480px) {
    .form-box{
        width: 100%;
        border-radius: 0px;
    }
}
/* Responsiveness:End */
.newUserDashboard__container {
  padding: 1rem 0;
}

.newUserDashboard-menu__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 1em;
  border-bottom: 1px solid #ddd;
}

.newUserDashboard-menuContainer__btns {
  float: right;
  display: flex;
}

.newUserDashboard-menuContainer__btns button {
  padding: 0.5rem 2rem;
  background-color: #007bff;
  color: #fff;
  border-radius: 0.2rem;
  border: none;
}

.newUserDashboard-menuContainer__btns a {
  margin-left: 1rem;
}

.dashboard_packageModal {
  width: 70%;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 75%);
}


@media (max-width: 770px) {
  .dashboard_packageModal {
    width: auto;
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  
  .newUserDashboard-menu__container {
    padding: 1rem;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
}
.admin-dashboard {
    padding: 20px;
    margin: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .adminbox {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 250px;
    display: flex;
    align-items: center;
  }
  
  .box-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .box-content h3 {
    margin-bottom: 5px;
  }
  
  .box-content p {
    font-size: 14px;
  }
  
@media (min-width: 768px) {
    .custom-card {
      width: 400px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
  
/* Base styles */

/* Adjust font size and line height for smaller screens */

  /* Blog card styles */
  .card-blog {
    margin-bottom: 20px;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    padding: 20px 125px;
    
    /* border: 1px solid rgba(0,0,0,.125); */
    /* border-radius: 0.25rem; */
  }
  .card-img-top{
    height: 500px;
}
  
  /* Blog title styles */
  .card-title {
    font-size: 28px;
    font-weight: 600;
  }

  .section-heading {
    font-size: 24px;
    font-weight: 600;
  }
  
  .author-name {
    font-size: 18px;
    font-weight: 600;
  }
  
  
  /* Blog content styles */
  .card-text {
    font-size: 18px;
  }
  
  /* Author styles */
  .author-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  /* Button styles */
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
  }
  
@media (min-width:768px) {
    .blog-section{
        padding: 0 70px;
    }
    
}

  /* Responsive styles */
  
  /* Adjust font size and line height for smaller screens */
  @media (max-width: 768px) {
   .card-blog{
    padding: 10px 10px;
   }


   .title-blog{
    font-size: 26px;
    font-weight: 600;
   }
  }
  
  /* Adjust card styles for smaller screens */
  @media (max-width: 576px) {
    .card {
      margin-bottom: 10px;
    }
  
    /* Reduce font size for blog titles and content */
    .card-title {
      font-size: 20px;
      font-weight: 600;
    }
  
    .author-name {
        font-size: 15px;
        font-weight: 600;
      }
      
    .card-text {
      font-size: 16px;
    }
  
    /* Reduce author image size */
    .author-image {
      width: 30px;
      height: 30px;
    }

    .card-img-top{
        height: 300px;
    }
  }
  
  /* Additional media queries for other screen sizes can be added as needed */
  
