.admin-dashboard {
    padding: 20px;
    margin: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .adminbox {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 250px;
    display: flex;
    align-items: center;
  }
  
  .box-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .box-content h3 {
    margin-bottom: 5px;
  }
  
  .box-content p {
    font-size: 14px;
  }
  