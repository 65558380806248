/* AddCityPage.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  border: 2px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.addcity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
} 

label {
  margin-bottom: 12px;
}
.addcity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}



input[type="text"],
textarea {
  /* width: 100%; */
  padding: 6px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing of the textarea */
  text-align: left; /* Align text to the left */
}


button {
  padding: 8px 16px;
  margin-bottom: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="button"] {
  /* background-color: #dc3545; */
  /* display:inline-flex; */
  /* margin: 5px; */
}

.spot-container {
  display: flex;
  align-items: center;
}

.spot-input {
  width: 100%;
  margin-bottom: 8px;
}

.spot-remove-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 8px;
  cursor: pointer;
}

/* Rest of the styles... */
