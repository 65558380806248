.userAddress-container label {
  font-size: 14px;
  color: #898989;
  margin: 0 0 0.5em 0;
}
.userAddress-container input {
  display: block;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 0.2em;
}

.userAddress-container {
  width: 100%;
  height: 56%;
  background: #fff;
  border-radius: 1em;
  box-shadow: 2px 2px 5px #ddd;
  padding: 1em 1.5em;
}

.userAddress-container form {
  display: flex;
  flex-direction: column;
}

.userAddress__section1 {
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #ddd;
}

.userAddress__section2 {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1em;
}

.userAddress__section2 div {
  width: 48%;
}

.userAddress__section3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.userAddress__section3 div {
  width: 28%;
}

.userAddress__section3 div:nth-of-type(1) {
  width: 38%;
}

.UserAddress-button {
  width: 60%;
  height: 2.2em;
  border-radius: 5em;
  border: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
  margin: 1em auto 0;
}

.UserAddress-button:hover {
  background-color: #0069d9;
}

@media (max-width: 645px) {
  .userAddress-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1.5em;
    height: fit-content;
  }
    
  .userAddress__section2 {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1em;
  }

  .userAddress__section2 div {
    width: 100%;
  }
}
