.bordered {
  width: 100%;
  background-color: whitesmoke;
  display: flex;
}

.card_body {
  height: 420px;
  padding: 35px;
  width: 50%;
  font-size: 2em;
  overflow-y: scroll;
  min-height: 0px;
}

.card_body::-webkit-scrollbar {
  display: none;
}

.card_body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.card-description {
  width: 100%;
  font-size: 18px;
  margin-top: 18px;
}

.img_random {
  /*   
  width: 81%;
  float: right;
  margin: -422px;
  margin-right: -468px; */
  width: 50%;
  padding: 35px;
  height: 420px;
}

.img_body {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

@media screen and (max-width: 750px) {
  .bordered {
    flex-direction: column;
  }
  .card_body {
    width: 100%;
  }
  .img_random {
    width: 100%;
  }
}

.touristSpots {
  width: 45%;
  margin: 31px;
  display: inline;
}

.body_touristSpots {
  padding: 15px;
}

.touristSpots {
  font-size: 18px;
  font-weight: 500;
}

#tourist_spots_ul {
  padding: 10px;
}

#tourist_spots_li {
  list-style-type: circle;
}

/* 
#request_booking_btn {
  justify-content: center;
  width: 14%;
  margin-left: 43%;
} */

.map_frame {
  width: 100%;
  height: 100%;
  border: 0;
}

.map {
  width: 85%;
  display: inline-block;
  margin: 31px;
}

@media screen and (max-width: 580px) {
  .second_row {
    flex-direction: column;
  }
  .touristSpots {
    width: 90%;
    margin-left: 5%;
  }
  .map {
    width: 90%;
    margin-left: 5%;
  }
}

.second_row {
  display: flex;
}

.h1-thirdCity {
  font-size: 2em;
}

.third-row {
  margin: 29px;
}

.subheading-third {
  font-size: 18px;
}

.DateTime {
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  justify-content: center;
  padding: 10px;
  height: auto;
}

.datediv, .timediv{
  display: flex;
  flex-wrap: nowrap;
  font-size: 18px;
  font-weight: bolder;
  align-items: baseline;
}

.datediv label, .timediv label{
  white-space: nowrap;
}

.date {
  padding-right: 9px;
  margin: 4px;
}

.time {
  margin: 4px;
}

.input_date, .input_time{
  width: 20rem;
  border: 2px solid black;
}

.input_time{
  font-size: 20px;
}

#request_booking_btn {
  margin: 2rem auto;
  width: fit-content;
  display: block;
  padding: 0.4rem 0.8rem;
  white-space: nowrap;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  align-items: center;
  border-radius: 0.2rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (max-width: 700px) {
  .card-description,
  .touristSpots {
    font-size: 14px;
  }
}

.featuredPhotographer-cityHome__container {
  width: 100%;
  /* height: max-content; */
  /* padding: 5em; */
  margin: 50px  0px auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media (max-width: 500px) {
  .featuredPhotographer-cityHome__container {
    padding: 1em;
  }
}

.fourth-row h1 {
  letter-spacing: 2px;
  font-size: 2em;
  padding: 10px;
}

.fourth-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 4rem;
  width: 100%;
  max-width: 1280px;
}

.city_cards {
  width: 100%;
  border-radius: 20px 20px 20px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  min-height: 450px;
  background: rgb(196, 195, 195);
}

.background {
  width: 100%;
  height: 170px;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 170px;
}
/* 

.background {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.background >div{
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 170px;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  background: #74ABE2;
  justify-content: center;
  align-items: center;
   
} */

/* 
.background img{
  width: 100%;
  height: 170px;
  border-radius: 20px 20px 0px 0px;
} */

.carousel-item {
  height: 100vh;
  min-height: 300px;
  z-index: 1;
}

.profileimage img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  background-position: center;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
}

.nameph {
  width: 100%;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.description_ph {
  width: 100%;
  height: 150px;
  font-size: 15px;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.description_ph p {
  font-size: 15px;
}

@media (max-width: 1024px) {
  .input_date, .input_time{
    width: 18rem;
  }
}

@media (max-width: 540px) {
  .DateTime{
    flex-direction: column;
    width: 80%;
    margin: 29px;
    padding: 0;
  }

  .input_date, .input_time{
    width: 100%;
  }
}



