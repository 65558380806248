
.card-container {
  width: 100%;
  max-width: 430px;
  height: 720px;
  background: #fff;
  border-radius: 6px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* box-shadow: 0px 1px 10px 1px #00000044; */
  overflow: hidden;
  display: inline-block;
  background-color: #eff3fa;
  margin-bottom: 3em;
}
@media (max-width: 600px) {
  .card-container {
    max-width: 100%; /* Adjust the width for smaller screens */
  
    margin-bottom: 2em;
  }
}
/* .upper-container {
  height: 150px;
  background: #7f00ff;
} */
.image-slider__container {
  /* max-width: 800px; */
  width: 100%;
  height: 45%;
  /* border: 7px solid #fff; */
  position: relative;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); */
  /* overflow: hidden; */
  /* background: #7f00ff; */
}
.image-box {
  display: flex;
  position: relative;
  height: 100%;
  width: 400%;
  justify-content: space-between;
}
.image-box {
  /* animation: imgBox 10s linear infinite; */
}
@keyframes imgBox {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -300%;
  }
}
/* .image-box:hover {
  animation-play-state: paused;
} */
.image-box .image {
  width: calc(100% - 100px);
  /* width: 100%; */
  /* height: 100%; */
}
.image-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-container {
  background: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 5px;
  transform: translate(0, -3em);
  /* float: right; */
  margin-left: auto;
  
  margin-right: 20px;
  
}
.image-container__img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.lower-container {
  height: 35%;
  /* background: #fff; */
  padding: 20px;
  padding-top: 0;
  text-align: center;
  text-transform: capitalize;
}
.lower-container h3,
h4 {
  box-sizing: border-box;
  line-height: 0.6;
  font-weight: lighter;
}
.lower-container h4 {
  color: #007bff;
  /* opacity: 0.6; */
  font-weight: bold;
  margin: 0.8em 0 0.8em 0;
}
.lower-container p {
  font-size: 16px;
  color: black;
  margin-bottom: 30px;
}
.lower-container .btn {
  padding: 12px 20px;
  background: #7f00ff;
  border: none;
  color: white;
  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease-in;
}
.lower-container .btn:hover {
  background: transparent;
  color: #7f00ff;
  border: 2px solid #7f00ff;
}

.request-button{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

@media (max-width: 500px) {
  .card-container {
    width: 100%;
  }
  .lower-container h3,
  h4 {
    font-size: 1.5rem;
  }
  .image-container {
    transform: translate(0, -3em);
  }
}

@media (max-width: 450px) {
  .image-container {
    transform: translate(0, -3em);
  }
}

@media (max-width: 400px) {
  .image-container {
    transform: translate(0, -3em);
  }
}


.containertext {
  display: flex;
  height: 90px;
  font-size: 16px; 
  font-weight: bold; 
  text-transform: capitalize;
  
}


/* .arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-400%);
 
} */

.prev-button,
.next-button {
  background: none;
  border: none;
  /* color: #000; */
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  background: rgb(0,0,0,.2);
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  transition: background-color 0.3s ease-in-out;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.active-slide-0 {
  margin-left: 0;
}
.active-slide-1 {
  margin-left: -280%;
}
/* .active-slide-2 {
  margin-left: -200%;
}
.active-slide-3 {
  margin-left: -300%;
} */


