
.main_container_ctus {
    min-height: 100vh;
    width: 100%;
    background: aliceblue;
    display: flex;
    align-items: center;
    justify-content : center;
  }
  .container_ctus {
    width: 85%;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
  }
  .container_ctus .content_ctus {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container_ctus .content_ctus .left-side_ctus {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    position: relative;
  }
  .content_ctus .left-side_ctus::before {
    content: "";
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
  }
  .content_ctus .left-side_ctus .details_ctus {
    margin: 14px;
    text-align: center;
  }
  .content_ctus .left-side_ctus .details_ctus i {
    font-size: 30px;
    color: #1e77d7;
    margin-bottom: 10px;
  }
  .content_ctus .left-side_ctus .details_ctus .topic {
    font-size: 18px;
    font-weight: 500;
  }
  .content_ctus .left-side_ctus .details_ctus .text-one,
  .content_ctus .left-side_ctus .details_ctus .text-two {
    font-size: 18px;
    color: #000;
  }
   
   
   
  .content_ctus .left-side_ctus .social_ctus i {
    font-size: 25px;
  }
   
  .container_ctus .content_ctus .right-side_ctus {
    width: 75%;
    margin-left: 75px;
  }
  .content_ctus .right-side_ctus .topic-text {
    font-size: 40px;
    font-weight: 600;
    color: #007bff;
  }

  @media (max-width: 540px) {
    .content_ctus .right-side_ctus .topic-text {
      font-size: 24px;
      font-weight: 600;
    }
  }
   
  .content_ctus .right-side_ctus .topic_para {
    margin: 14px 0;
  }
   
  .right-side_ctus .input-box_ctus {
    height: 50px;
    width: 100%;
    margin: 12px -5px;
  }
  .right-side_ctus .input-box_ctus input,
  .right-side_ctus .input-box_ctus textarea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #f0f1f8;
    border-radius: 6px;
    padding: 0 15px;
    resize: none;
  }
  .right-side_ctus .message-box {
    min-height: 110px;
  }
  .right-side_ctus .input-box_ctus textarea {
    padding: 20px;
    margin: 10px;
  }
  .right-side_ctus .button_ctus {
    display: inline-block;
    margin-top: 12px;
  }
  .right-side_ctus .button_ctus button[type="submit"] {
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: #007bff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .right-side_ctus .button_ctus button[type="submit"]:hover {
    background: #005bbc;
  }
   
  @media (min-width: 773px) {
    .content_ctus .left-side_ctus .social_ctus {
      color: #52a0f3;
      width: 14vw;
      display: flex;
      justify-content: space-evenly;
      margin-top: 8px;
    }
  }
   
  @media (max-width: 950px) {
    .container_ctus {
      width: 90%;
      padding: 30px 40px 40px 35px;
    }
    .container_ctus .content_ctus .right-side_ctus {
      width: 75%;
      margin-left: 55px;
    }
  }
  @media (max-width: 820px) {
    .container_ctus {
      margin-top: 40px;
      margin-bottom: 40px;
      height: 100%;
    }
    .container_ctus .content_ctus {
      flex-direction: column-reverse;
    }
    .container_ctus .content_ctus .left-side_ctus {
      width: 100%;
      flex-direction: row;
      margin-top: 40px;
      justify-content: center;
      flex-wrap: wrap;
    }
   
    .content_ctus .left-side_ctus .social_ctus {
      margin-top: 9px;
    }
   
    .container_ctus .content_ctus .left-side_ctus::before {
      display: none;
    }
    .container_ctus .content_ctus .right-side_ctus {
      width: 100%;
      margin-left: 0;
    }
  }
   
  @media (max-width: 772px) {
    .content_ctus .left-side_ctus .social_ctus i {
      padding: 0 5px;
    }
  }
   
  
  
  
  