#portfolio-header {
  width: fit-content;
  margin: 1em auto;
}
.div2subhead_portfolio {
  width: 80%;
  margin: 20px 70px ;
  font-size: 18px;
  text-align: justify;
}
.media-type-switch {
  width: 100%;
  padding: 10px;
}

.media-type-switch label {
  font-size: 20px;
  margin-right: 10px;
  
  align-items: center;
}

.media-type-switch input[type="radio"] {
  margin-right: 5px;
  transform: scale(1.5); /* Adjust the scale factor as needed */
}


/* .div2subhead_portfolio p h1 {
  display: inline;
  font-size: 20px;
} */
.search_heading_packages {
  align-items: center;
  margin-left: 426px;
  /* padding-top: -36px; */
  margin-top: -35px;
}


.newheader {
  text-align: center;
  text-transform: uppercase;
  padding: 32px;
  background-color: #0a0a23;
  color: #fff;
  border-bottom: 4px solid #fdb347;
}

.newgallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px 10px;
}

.newgallery img {
  width: 100%;
  max-width: 420px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.newgallery::after {
  content: "";
  width: 420px;
}



.hovereffects {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  margin-bottom: 10px;
}

.hovereffects .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hovereffects img {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.hovereffects h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 10px;
}

.hovereffects a.info {
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 50px 0 0;
  padding: 7px 14px;
}

.hovereffects a.info:hover {
  box-shadow: 0 0 5px #fff;
}

.hovereffects:hover h2,
.hovereffects:hover a.info {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.hovereffects:hover a.info {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.text-center.m-4.heading_portfolio-main {
  margin: 20px;
  padding: 50px;
  font-size: 3em;
}

a.btn_portfolio {
  text-decoration: NONE;
}
.ending_Portfolio_text {
  font-weight: 900;
}
/* Masonry styling */

.search_portfolio {
  width: 36vw;
  margin: auto;
  text-align: center;
  margin-bottom: 21px;
}

.search_portfolio h3 {
  width: 182% !important;
  margin-inline: -43% !important;
  padding-bottom: 4% !important;
  text-align: center !important;
}

.gal-container {
  padding: 5px;
}
.gal-item {
  overflow: hidden;
  padding: 15px;
}
.gal-item .box {
  height: 350px;
  overflow: hidden;
}
.box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
}
.gal-item a:focus {
  outline: none;
}
.gal-item a:after {
  content: "\e003";
  font-family: "Glyphicons Halflings";
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  right: 3px;
  left: 3px;
  top: 3px;
  bottom: 3px;
  text-align: center;
  line-height: 350px;
  font-size: 30px;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.gal-item a:hover:after {
  opacity: 1;
}
.modal-open .gal-container .modal {
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-open .gal-item .modal-body {
  padding: 0px;
}
.modal-open .gal-item button.close {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #000;
  opacity: 1;
  color: #fff;
  z-index: 999;
  right: -12px;
  top: -12px;
  border-radius: 50%;
  font-size: 15px;
  border: 2px solid #fff;
  line-height: 25px;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.35);
}

@media (min-width: 768px) {
  .gal-container .modal-dialog {
    width: 55%;
    margin: 50 auto;
  }
}

@media screen and (max-width: 1024px) and (min-width: 580px) {
  .col-md-7 {
    max-width: 99.333333% !important;
  }

  .col-md-5 {
    max-width: 99.666667% !important;
  }

  .col-md-4 {
    max-width: 99.333333% !important;
  }

  .col-md-8 {
    max-width: 99.666667% !important;
  }
}

@media (mix-width: 768px) {
  .gal-container .modal-content {
    height: 250px;
  }

  /* .search_portfolio {
    width: 39vw;
    margin: auto;
    margin-bottom: 21px;
    margin-left: 498px;
  } */
}

@media (max-width: 767px) {
  .text-center.m-4.heading_portfolio-main {
    font-size: 1em;
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 500px) {
  .search_portfolio h3 {
    font-size: x-large;
  }
}

@media (max-width: 416px) {
  .search_portfolio h3 {
    font-size: larger;
  }
}

* {
  margin: 0;
  padding: 0;
}
.main_container_pf {
  width: 93%;
  margin: auto;
}

.container_pf {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.container_pf .img_pf1 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf2 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf4 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf3 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf5 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf5 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf6 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf6 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf8 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf8 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf7 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf7 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf9 {
  width: 53.7vw;
  height: 409px;
}
.container_pf .img_pf9 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container_pf .img_pf10 {
  width: 36vw;
  height: 409px;
}

.container_pf .img_pf10 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 990px) {
  .container_pf {
    flex-direction: column;
    align-items: center;
  }

  .container_pf .img_pf {
    margin: 15px 0;
    width: 89vw;
    /* height: auto; */
  }
}

@media screen and (max-width: 560px) {
  .div2subhead_portfolio {
    font-size: 14px;
    margin: 40px auto;
  }

  .container_pf {
    margin: 0;
  }
  .container_pf .img_pf {
    height: auto;
    margin: 8px 0;
  }

  .container_pf .img_pf img {
    height: auto;
  }
}


.grid--container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(8, 1fr);
}
.grid--image {
  /* background-image: url("https://images.unsplash.com/photo-1494976388531-d1058494cdd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"); */
  background-size: cover;
  background-position: center;
  grid-column: 1 / 7;
  grid-row: 1 / 7;
}
.grid--content {
  background-color: rgba(255,255,255,0.8);
 height: fit-content;

  grid-column: 6 / -1;
  grid-row: 4 / -1;
}
.card--title {
  font-family: 'Blinker', sans-serif;
}
/* button {
  border: 1px solid black;
  
  &:hover {
    background: #000;
    color: white;
    box-shadow: 0 0 32px rgba(0,0,0,0.2);
  }
} */

@media (max-width: 1000px) {
  .grid-container {
    height: 100%;
  }
  .grid--content {
    background-color: rgba(255,255,255,0.9);
    /* grid-column: 5 / span 6; */
  }
}
@media (max-width: 768px) {
  .grid--image {
    grid-column: 1 / -1
  }
 
  .grid--content {
    background-color: rgba(255,255,255,0.9);
    grid-column: 2 / span 8;
    /* grid-row: 6 / -1; */
    min-height: 100%;
  }
}
@media (max-width: 500px) {
  .grid--container {
    box-shadow: 0 0 32px rgba(0,0,0,0.5);
  }
  .grid--image {
    grid-column: 1 / -1;
    grid-row: 1 / span 4;
  }
  .grid--content {
    background-color: white;
    grid-column: 1 / -1;
    /* grid-row: 5 / -1; */
    min-height: 100%;
  }
}


.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}



.loader-text {
  font-size: 24px;
  margin-top: 16px;
  color: #36D7B7;
}