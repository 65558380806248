*{

    margin: 0;

    padding: 0;

    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.admin-section{

    display: flex;

    justify-content: center;

    align-items: center;

    min-height: 100vh;

    width: 100%;

    

    background: url("https://i.postimg.cc/SxB07GjW/beach-bg.jpg")no-repeat;

    background-position: center;

    background-size:cover; 

}

.form-box-login{

    position: relative;

    width: 320px;

    height: 350px;

    background: transparent;

    border:none;

    border-radius: 20px;

    backdrop-filter:blur(15px) brightness(80%);

    

    display: flex;

    justify-content: center;

    align-items: center;

}

.login_head{

    font-size: 2em;

    color:#fff;

    text-align: center;

}

.inputbox{

    position: relative;

    margin: 30px 0;

    width: 270px;

    border-bottom: 2px solid #fff;

}

.inputbox label{

    position: absolute;

    top:50%;

    left: 5px;

    transform: translateY(-50%);

    color: #fff;

    font-size: 1em;

    pointer-events:none;

    transition: .5s;

}

/* animations: start */

input:focus ~label,

input:valid ~label{

    top: -5px;

}

/* animation:end */

.inputbox input{

    width: 100%;

    height: 50px;

    background: transparent;

    border: none;

    outline: none;

    font-size: 1em;

    padding: 0 35px 0 5px;

    color: #fff;

}

.inputbox ion-icon{

    position: absolute;

    right:8px;

    color: #fff;

    font-size: 1.2em;

    top:20px;

}



.admin_button{

    width: 100%;

    height: 40px;

    border-radius: 40px;

    background-color: #fff;

    border:none;
    color: black;
    outline: none;

    cursor: pointer;

    font-size: 1em;

    font-weight: 600;

}


/* Responsiveness:Start */
@media screen and (max-width:480px) {
    .form-box{
        width: 100%;
        border-radius: 0px;
    }
}
/* Responsiveness:End */