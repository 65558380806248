.how {
  background-color: aliceblue;
  max-width: 82%;
  min-height: 350px;
  margin-top: 33px;
}

.div2subhead_how {
  /* margin: 70px auto; */
  width: 100%;
  font-size: 18px;
  /* margin-bottom: -66px; */
  margin-top: 27px;
  /* text-align: center; */
}

.div2subhead_how p {
  text-align: center;
  font-size: 18px;
}

.howItWorks-section1__container {
  height: 380px;
  display: flex;
  /* align-items: ; */
}

.howItWorks-section1__container .left,
.howItWorks-section1__container .right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.howItWorks-section1__container .left li,
.howItWorks-section1__container .right li {
  display: flex;
  /* align-items: center; */
}

/* .left {
  width: 31%;
  margin-left: 49px;
  margin: 85px;
}

.right {
  width: 34%;
  float: right;
  margin-top: -380px;
  margin-right: 55px;
} */

li {
  list-style: none;
}

.img_icon {
  width: 50px;
  /* height: 46px; */
  height: 50px;
  margin-right: 1em;
  /* margin: -78px; */
  /* margin-top: 24px; */
}

/* .left li:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 28px;
  width: 2px;
  height: 150%;
} */

/*search bar styling   */

.sb-example-1_how {
  max-width: 100%;
}

.sb-example-1_how .search_how {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 0px;
  margin-left: 513px;
  padding-bottom: 16px;
  /* margin-right: 619px; */
}

.sb-example-1_how .searchTerm_how {
  width: 20%;
  border: 3px;
  border-right: none;
  padding: 24px;
  border-radius: 5px 0 0 5px;
  outline: none;
  height: 50px;
  background: transparent;
  border: black solid 1px;
}

.sb-example-1_how .searchTerm_how:focus {
  color: #0f0e0e;
}

.sb-example-1_how .searchButton_how {
  width: 40px;
  height: 50px;
  border: 1px solid black;
  background: transparent;
  text-align: center;
  color: black;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.Top-band_home {
  text-align: center;
  background-color: #00008b;
  position: relative;
  z-index: 1;
  width: 100%;
}

.search_how {
  width: 100%;
  margin-left: 0;
  padding-bottom: 26px;
  padding-top: 11px;
}

.search_heading {
  /* align-items: center;
  margin-left: 426px; */
  width: 100%;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.how-h1 {
  margin: 15px;
  font-weight: 700;
}
@media (max-width: 1230px) {
  .search_heading {
    padding-top: 68px;
    width: 75%;
    margin: auto;
    text-align: center;
  }
  .search_how {
    margin: auto;
  }
}

@media (max-width: 840px) {
  .how {
    max-width: 85%;
    margin-left: 51px;
  }
  .how-h1 {
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: bolder;
  }
  .div2subhead_how p {
    text-align: center;
    margin: 30px;
    margin-bottom: 18px;
  }
  .how {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 908px;
  }
  /* .img_icon {
    height: 42px;
  } */
  .left {
    width: 70%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0;
    margin: 6px;
  }
  li p {
    font-size: 15px;
    /* letter-spacing: 1.4px; */
  }
  li h4 {
    font-size: 18px;
    line-height: 1.2;
    /* letter-spacing: 3px; */
    font-weight: bolder;
    /* padding: 7px; */
  }
  .card_content {
    text-align: center;
  }
  .right {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin: 6px;
  }
  .search_heading {
    padding-top: 68px;
    width: 75%;
    margin: auto;
    text-align: center;
  }
  .search_how {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .right {
    display: block;
  }
  .card_content {
    width: 50vw !important;
  }
  .search_heading h3 {
    font-size: large;
  }
  .searchTerm::placeholder {
    font-size: 10px !important;
  }
  .how {
    margin-left: 28px;
    padding: 15px;
  }
}