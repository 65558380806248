.NewDashboard-Body__container {
  width: 85%;
  min-height: 100vh;
  max-height: fit-content;
  /* height: 100vh; */
  padding: 1em 3em;
  background-color: #eff3fa;
}

.NewDashboard-header {
  height: 10vh;
  display: flex;
  align-items: center;
}

.NewDashboard-header .menu-btn {
  display: none;
  font-size: 2rem;
  margin-right: 1rem;
}

.dashboard-heading {
  font-size: 2rem;
  margin: 0;
}
.NewDashboard-header button {
  width: 12em;
  height: 3em;
  border-radius: 5em;
  border: none;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
}

.NewDashboard-header button:hover {
  background-color: #0069d9;
}

.NewDashboard-booking__route {
  height: 5vh;
  /* border: 1px solid black; */
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #ccc;
}

.NewDashboard-booking__route h5 {
  height: 100%;
  border-bottom: 5px solid #007bff;
  border-radius: 4px;
  margin-bottom: -2px;
}

.NewDashboard-booking__confirmed {
  margin-right: 1em;
  text-decoration: none;
}

.NewDashboard-photoshootCard {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  margin: 1em 0;
}

.NewDashboard-photoshootCard__section2 {
  width: 55%;
  height: 32em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1125px) {
  .NewDashboard-photoshootCard {
    width: 100%;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
    gap: 1rem;
  }

  .NewDashboard-photoshootCard__section2 {
    width: 100%;
    gap: 1rem;
    height: fit-content;
    flex-direction: column;
  }
}
@media (max-width: 810px) {
  .NewDashboard-Body__container {
    width: 100%;
    padding: 1em 0.5em;
  }

  .NewDashboard-photoshootCard {
    padding: 1rem;
  }

  .NewDashboard-header,
  .NewDashboard-booking__route {
    margin-left: 1rem;
  }

  .NewDashboard-header .menu-btn {
    display: block;
  }
}

@media (max-width: 645px) {
  .NewDashboard-Body__container {
    height: max-content;
  }
  .NewDashboard-photoshootCard {
    display: block;
    /* text-align: center; */
  }
  .NewDashboard-photoshootCard__section2 {
    width: 100%;
  }
}

/*============ CSS for LEADS ===========*/
.table-responsive{
  position: relative;
}
.cell-1 {
  cursor: pointer;
}
.thead {
  background: #dddcdc;
  position: sticky;
  top: 0;
  transform: translateY(-1px);
}
.thead th {
  background-color: #007bff;
  color: #fff;
  text-align: center;
} 
.table-body td {
  text-align: center;
} 