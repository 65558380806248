#navright-backToTop {
  display: inline-block;
  position: fixed;
  top: 90vh;
  right: 10px;
  /* left: 50%;
  transform: translateX(-50%); */
  z-index: 1000000000;
  cursor: pointer;
}

.backToTop-icon,
.backToBottom-icon{
  font-size: 2.5rem;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.backToTop-icon path {
  stroke: #007bff;
}
