.ProfilePersonalDetails__container label {
  font-size: 14px;
  color: #898989;
  margin: 0 0 0.5em 0;
}
.ProfilePersonalDetails__container input {
  display: block;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 0.2em;
}

.ProfilePersonalDetails__container {
  width: 38%;
  height: 30em;
  background: #fff;
  border-radius: 1em;
  box-shadow: 2px 2px 5px #ddd;
  padding: 1em 1.5em;
  margin-top: 1em;
}

.ProfilePersonalDetails__FormContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: flex-end; */
}

.ProfilePersonalDetails-section1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProfilePersonalDetails-section1 > img{
  margin: 0.5rem auto;
}

.ProfilePersonalDetails-section1__inputContainer {
  display: block;
  width: 70%;
}

/* .ProfilePersonalDetails-section1__inputContainer div {
  display: block;
} */

.ProfilePersonalDetails-section2 {
  width: 100%;
}

.ProfilePersonalDetails-section3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ProfilePersonalDetails-section3 div:nth-of-type(1) {
  width: 30%;
}

.ProfilePersonalDetails-section3 div:nth-of-type(2) {
  width: 65%;
}

.ProfilePersonalDetails-button {
  width: 80%;
  height: 2.2em;
  border-radius: 5em;
  border: none;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
  margin: 1rem auto 0;
}

.ProfilePersonalDetails-button:hover {
  background-color: #0069d9;
}

@media (max-width: 1125px) {
  .ProfilePersonalDetails__container label {
    font-size: 13px;
  }

  .ProfilePersonalDetails__container {
    width: 100%;
  }

  .ProfilePersonalDetails-section3 div:nth-of-type(1) {
    width: 34%;
  }
}

@media (max-width: 645px) {
  .ProfilePersonalDetails__container {
    width: 90%;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }

  .ProfilePersonalDetails-section1 {
    align-items: flex-start;
    flex-direction: column;
  }
  
  .ProfilePersonalDetails-section1__inputContainer {
    display: block;
    width: 100%;
  }
}
