#popupContainer {
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
}

#popupCallbackContainer {
  width: 95%;
  /* height: 80vh; */
  /* margin: 1em auto; */
  margin: 5em auto;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  /* background-color: #eff3fa;
  border-radius: 1.5em;
  padding: 3em;*/
}

#popupCallbackContainer__inner {
  width: 50vw;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eff3fa;
  border-radius: 1.5em;
  padding: 3em;
  /* position: fixed; */
  /* top: 6em; */
  overflow: hidden;
}

#popupCallbackContainer #closeIcon {
  display: none;
}

#popupContainer__inner {
  width: 50vw;
  height: 85vh;
  /* margin: 1em auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: #eff3fa;
  border-radius: 1.5em;
  padding: 2em;
  position: fixed;
  top: 4em;
  overflow: hidden;
}

#popupHeader h1 {
  width: auto;
  text-align: center;
  font-size: 2.5rem;
  /* font-weight: 700; */
}

#closeIcon {
  color: rgb(139, 137, 137);
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 24px;
}

#popupForm__inputContainer {
  display: flex;
  width: 50vw;
  /* height: 30vh; */
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 1em;
}

.popupForm__input {
  display: flex;
  flex-direction: column;
  
  
  /* margin-bottom: 0.5em; */
}

.popup__TextField {
  width: 20vw;
  padding: 0.5em 1em;
  /* margin: 8px 0; */
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.popupForm__input label {
  font-size: 14px;
  color: #000;
  margin: 0;
}

#popup-photoshootDetails__container {
  width: 43vw;
  /* height: 60vh; */
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}

#popup-photoshootType__container {
  width: 23vw;
}

#photoshootType__heading label {
  font-size: 16px;
  color: #000;
  /* margin: 0.5em 0; */
  width: 100%;
}

.popup-photoshootType {
  width: 30vw;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
}

.popup-photoshootType__Section1 {
  width: 40%;
}
.popup-photoshootType__Section2 {
  width: 50%;
}

.popup-photoshootType input {
  margin-right: 0.5em;
}

.popup-photoshootType label {
  margin-bottom: 0.1em;
  font-weight: bold;
}

#popup-button {
  margin: 2em auto;
  /* width: 25em; */
  padding: 0.8em 6em;
  border-radius: 5em;
  border: none;
  text-decoration: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
}

#popup-button:hover {
  background-color: #0069d9;
}

#photoshoot-city__container {
  width: 20vw;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
}

#photoshoot-city {
  padding: 0.5em 1em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 15px;
}

#popup__requestField {
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 15px;
  resize: none;
}

@media (max-width: 1500px) {
  #popupContainer__inner,
  #popupCallbackContainer__inner {
    width: 60%;
  }
  .popup-photoshootType {
    width: 25vw;
    font-size: 14px;
  }
  #popupCallbackContainer iframe {
    width: 550px;
    height: 320px;
    margin-right: 1em;
  }
}
/* @media (max-width: 1200px) {
  #popupContainer__inner {
    width: 70%;
  }
} */

@media (max-width: 1000px) {
  #popupCallbackContainer {
    height: auto;
    flex-direction: column;
  }
  #popupCallbackContainer iframe {
    margin-bottom: 1em;
    margin-right: 0;
  }
  #popupContainer__inner,
  #popupCallbackContainer__inner {
    width: 65vw;
  }
  #popup-photoshootDetails__container {
    width: 90%;
  }
  .popup-photoshootType {
    font-size: 14px;
  }
  /* #popupForm__inputContainer {
    width: 100%;
    margin-top: 0.5em;
  }
  .popup__TextField {
    width: 18vw;
    padding: 0.2em 1em;
  } */
  
#popupContainer__inner {
  height: auto;
  top: auto;
}
}

@media (max-width: 910px) {
  #popupContainer__inner,
  #popupCallbackContainer__inner {
    width: 70vw;
    /* height: 75vh; */
  }
  #popupForm__inputContainer {
    width: 60vw;
  }
  .popup__TextField {
    width: 25vw;
  }
  .popup-photoshootType {
    width: 32vw;
  }
  #photoshoot-city__container {
    width: 25vw;
  }
}

@media (max-width: 820px) {
  #popupContainer__inner,
  #popupCallbackContainer__inner {
    width: 80vw;
  }
  #popupForm__inputContainer {
    width: 70vw;
  }
  .popup__TextField {
    width: 30vw;
  }
  .popup-photoshootType {
    width: 32vw;
  }
  #photoshoot-city__container {
    width: 30vw;
  }
}

@media (max-width: 765px) {
  .popup__TextField {
    padding: 0.2em 1em;
  }
  .popup-photoshootType {
    max-height: 20vh;
    overflow-y: scroll;
    display: block;
    width: 25vw;
  }
  ::-webkit-scrollbar-thumb{
    color: red;
  }
  .popup-photoshootType__Section1,
  .popup-photoshootType__Section2 {
    width: 100%;
  }
  #popupHeader {
    width: 70vw;
  }
  #popupHeader h1 {
    width: 65vw;
    font-size: 24px;
  }
  #popup-button {
    padding: 0.6em 5em;
  }
  /* #popupCallbackContainer__inner #popupHeader h1 {
    margin-top: 4em;
  } */
}

@media (max-width: 600px) {
  #popupCallbackContainer iframe {
    width: 400px;
    height: 280px;
  }
  #popupCallbackContainer #popupContainer__inner,
  #popupCallbackContainer__inner {
    width: 90vw;
  }
  /* #popup-photoshootType__container {
    width: 60vw;
  } */
  #popupContainer__inner {
    height: 80vh;
    top: 4em;
  }

  .popup-photoshootType {
    max-height: 20vh;
    overflow-y: scroll;
    display: block;
    /* width: 0vw; */
  }
  .popup-photoshootType__Section1 {
    width: 100%;
  }
  .popup-photoshootType__Section2 {
    width: 100%;
  }
  #photoshootType__heading label {
    width: 40vw;
  }
  #popup-button {
    padding: 0.4em 4em;
  }
  #popup-photoshootType__container {
    margin-bottom: 1em;
  }
}

@media (max-width: 450px) {
  #popupCallbackContainer iframe {
    width: 380px;
    height: 280px;
  }
  #popupContainer__inner,
  #popupCallbackContainer__inner {
    width: 95vw;
    /* max-height: 80vh; */
    overflow-y: scroll;
  }
  #popupHeader {
    width: 80vw;
  }
  #popupHeader h1 {
    width: 75vw;
    font-size: 21px;
  }
  #popupForm__inputContainer {
    width: 80vw;
  }
  .popup__TextField {
    width: 38vw;
  }

  #popup-photoshootDetails__container {
    display: block;
    margin-top: 0.6em;
    /* padding: -1em; */
  }
  #popup-photoshootType__container {
    width: 70vw;
  }
  .popup-photoshootType {
    max-height: 20vh;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .popup-photoshootType__Section1 {
    width: 40%;
  }
  .popup-photoshootType__Section2 {
    width: 50%;
  }
  #photoshoot-city__container {
    width: auto;
  }
}

@media (max-width: 380px) {
  #popupCallbackContainer iframe {
    width: 310px;
    height: 185px;
  }
  #popupHeader h1{
    font-size: 21px;
  }
  #closeIcon {
    top: 1rem;
    right: 1rem;
  }
  #popup-button {
    padding: 0.4em 3em;
  }
  #popup-photoshootDetails__container {
    width: 110%;
  }
  #popup-photoshootType__container {
    width: 100vw;
    margin-bottom: 1em;
  }
  #photoshootType__heading label {
    width: 45vw;
  }
  #photoshoot-city__container {
    width: auto;
  }
}
