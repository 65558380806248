.featured-partner__container {
  width: 100%;
  /* margin: 0 auto; */
  text-align: center;
}

.image-slider__container {
  width: 100%;
  height: 45%;
  overflow: hidden;
  margin: 4% 0;
}
.image-box {
  display: flex;
  height: 100%;
  width: 200%;
  justify-content: space-between;
  align-items: center;
}
.image-box {
  animation: imgBox 10s linear infinite;
}
@keyframes imgBox {
  0% {
    /* margin-left: 0;*/
    transform: translateX(0%);
  }
  100% {
    /* margin-left: -100%;*/
    transform: translateX(calc( -100% + 100vw));
   
  }
}
.image-box .image {
  width: calc(100% - 100px);
  height: 100%;
}
.image-box img {
  height: 60%;
  width: 60%;
  object-fit: cover;
}


@media (max-width: 770px) {
  .image-box {
    width: fit-content;
    gap: 1rem;
  }

  .image{
    width: fit-content;
    height: auto;
  }

  .image img{
    width: 120px;
    height: auto;
  }

  .image-box {
    animation: imgBox 10s linear infinite;
  }
}

@media (max-width: 500px) {
  .featured-partner__container h1{
    font-size: 25px;
  }

  .image-box {
    animation: imgBox 15s linear infinite;
  }
}
