/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  } */

.wrapper {
  display: flex;
  margin-top: 60px;
  text-align: center;
}

.wrapper h1 {
  font-size: 48px;
  color: black;
  margin-bottom: 2em;
}

@media (max-width: 540px) {
  .wrapper h1 {
    font-size: 25px;
    font-weight: 600;
    color: black;
    margin-bottom: 2em;
  }
}

.our_team {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.our_team .team_member {
  /* width: 250px; */
  margin: 1px;
  background: transparent;
  padding: 20px 10px;
  width: 35vw;
}

.our_team .team_member .member_img {
  background: #e9e5fa;
  max-width: 190px;
  width: 100%;
  height: 190px;
  margin: 0 auto;
  border-radius: 50%;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.our_team .team_member .member_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.our_team .team_member h3 {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 2px;
  margin: 15px 0 0px;
  margin-bottom: 7px;
}

@media (max-width: 540px) {
  .our_team .team_member h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.our_team .team_member span {
  font-size: 16px;
}

/* .our_team .team_member1 {
  width: 30vw;
}

.our_team .team_member2 {
  width: 30vw;
}
.our_team .team_member3 {
  width: 30vw;
}
.our_team .team_member4 {
  width: 30vw;
}
.our_team .team_member5 {
  width: 30vw;
}

.our_team .team_member6 {
  margin-bottom: 5vh;
} */

.our_team .team_member p {
  margin-top: 20px;
  font-size: 17px;
  line-height: 20px;
}

.our_team .team_member .member_img .social_media {
  position: absolute;
  top: 5px;
  left: 5px;
  background: rgba(0, 0, 0, 0.65);
  width: 95%;
  height: 95%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: all 0.5s ease;
}

.our_team .team_member .member_img .social_media .item {
  margin: 0 10px;
}

.our_team .team_member .member_img .social_media .fab {
  color: #8c7ae6;
  font-size: 20px;
}

.our_team .team_member .member_img:hover .social_media {
  transform: scale(1);
}

@media screen and (max-width: 565px) {
  .our_team .team_member {
    width: 100%;
  }
  /* .our_team .team_member2 {
    width: 44vw;
  }
  .our_team .team_member3 {
    width: 44vw;
  }
  .our_team .team_member4 {
    width: 44vw;
  }
  .our_team .team_member5 {
    width: 44vw;
  }
  .our_team .team_member6 {
    width: 63vw;
  } */

  /* .our_team .team_member h3 {
    font-size: 17px;
  }

  .member_img1 {
    width: 96% !important;
    height: 166px !important;
  }

  .member_img2,
  .member_img3,
  .member_img4,
  .member_img5 {
    width: 96% !important;
    height: 166px !important;
  }

  .member_img6 {
    width: 81% !important;
    height: 156px !important;
  } */
}

/* @media screen and (max-width: 400px) {
  .member_img1 {
    width: 101% !important;
    height: 153px !important;
  }

  .member_img2,
  .member_img3,
  .member_img4,
  .member_img5 {
    width: 101% !important;
    height: 153px !important;
  }
}

@media screen and (max-width: 352px) {
  .member_img1 {
    width: 58% !important;
    height: 155px !important;
  }

  .member_img2,
  .member_img3,
  .member_img4,
  .member_img5 {
    width: 101% !important;
    height: 142px !important;
  }
} */
