.background_contact {
  background: url("../../All_Images/backgroundcontact.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
  max-width: 200vh;
}
.contactuscomponents_text {
  color: aliceblue;
}
.contactuscomponents_text p {
  text-align: left;
}
.form_contactus {
  margin: 20px;
  padding: 20px;
  max-height: 100%;
  transform: translateY(10%);
}

#contactname {
  margin: 10px;
  padding: 20px;
  outline: none;
}
#contactemail {
  margin: 10px;
  padding: 20px;
  outline: none;
}
#contactphone {
  margin: 10px;
  padding: 20px;
  outline: none;
}
.msg_contactus textarea {
  outline: none;
  max-width: 100%;
  margin: 10px;
}
.button_contactuspage button {
  background-color: #87ceeb;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.social_address_contactus {
  display: flex;
  justify-content: space-evenly;
  color: white;
  font-size: 25px;
  margin: 10px;
  padding: 20px;
}

.social-media-contactuspage {
  font-size: 25px;
  transform: translateY(5%);
}
.social-media-contactuspage a {
  color: white;
  margin: 20px;
}
@media (max-width: 767px) {
  .social_address_contactus {
    display: block;
  }
  .background_contact {
    height: 200vh;
  }
}
