.p_register-container {
    position: relative;
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
}

.pResister_header {
    background: #ffffff;
    color: #007bff;
    text-align: center;
    width: 80%;
    margin: 0 auto 10px;
    padding: 0.4rem 1rem;
    border-bottom: 2px solid #007bff;
}

.pResister_header h3 {
    margin: 0;
}

.p_register-container .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 25px;
    cursor: pointer;
}

.pRegister_form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.p-form-group button {
    width: auto;
    padding: 0.5rem 3.5rem;
    border: none;
    outline: none;
    color: white;
    font-size: 14px;
    font-weight: normal;
    border-radius: 2px;
    text-transform: uppercase;
    background: #007bff;
}

.p-form-group button:hover {
    background: #005bbc;
}

@media (max-width: 770px) {
    .pResister_header>h3 {
        font-size: 21px;
    }
}