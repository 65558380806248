/* .pending-booking__container {
  width: 100%;
  height: auto;
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  border-radius: 1em;
  background-color: #fff;
  box-shadow: 2px 2px 5px #ddd;
}

.pending-booking__container h6 {
  margin-bottom: 0;
}

.pending-booking__container p {
  margin-bottom: 0;
} */

.NewDashboard-photoshootCard {
  width: 100%;
  height: auto;
  display: flex;
  /* flex-flow: row wrap; */
  flex-wrap: wrap;
}
.pending-booking__container {
  width: 16em;
  height: 17em;
  /* border: 1px solid black; */
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  border-radius: 0.5em;
  background-color: #fff;
  box-shadow: 0px 0px 9px #00000029;
  border: 2px solid #d1d1d1;
  /* font-family: "Montserrat", sans-serif !important; */
  font-weight: 100;
}

.pending-booking__container h6 {
  margin-bottom: 0;
}

.pending-booking__inner h3 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 1.3rem;
  text-shadow: 2px 2px #eaeaea;
}

.pending-booking__container p {
  margin-bottom: 0;
}

.pendingBookings-action__container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.no_confirm_booking_heading{
  margin-top: 2rem;
}

@media (max-width: 810px) {
  .NewDashboard-photoshootCard {
    justify-content: center;
  }
}
