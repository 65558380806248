.heading_career {
  margin: 20px 0;
}

.image_space {
  height: 25vh;
}

.upload_resume {
  display: flex !important;
  justify-content: center !important;
  width: 48vw !important;
  margin: auto !important;
  align-items: center !important;
  text-align: center !important;
  margin-top: 5vh !important;
  margin-bottom: 4vh !important;
}

.second {
  text-align: center;
  font-size: 18px;
}
.second h4 {
  line-height: 1.6;
  padding: 20px;
}

.main {
  text-align: center;
  padding: 15px;
}

.main h1 {
  padding: 10px;
  font-weight: 700;
}

@media (max-width: 540px) {
  .main h1 {
    font-size: 28px;
    font-weight: 600;
  }
}

.main p {
  font-size: 18px;
}

.join {
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.send {
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
/* .input_resume {
  width: 48%;
  margin-left: 2vw;
} */

input[type="file"] {
  display: none;
}

.upload_btn label {
  color: white;
  height: 60px;
  width: 200px;
  background-color: #007bff;
  /* position: absolute; */
  margin: auto;
  margin-top: 6vh;
  margin-bottom: 4vh;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.upload_btn label:hover {
  background-color: #026bdb;
}

@media (max-width: 1200px) {
  .upload_resume {
    font-size: 18px;
  }
}
@media (max-width: 1100px) {
  .upload_resume {
    font-size: 17px;
  }
}
@media (max-width: 1000px) {
  .upload_resume {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .upload_resume {
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .upload_resume {
    font-size: 1.5vw;
  }
}