.offerAlert-container {
  width: 100%;
  height: 10em;
  margin: 0 auto;
  background-color: #007bff;
  text-align: center;
}

.offerAlert__inner {
  margin: 0;
  font-size: 1em;
  background-color: #007bff;
  color: #fff;
  padding: 0.5em 0;
}

@media (max-width: 710px) {
  .offerAlert__inner {
    font-size: 0.9em;
  }
}

@media (max-width: 450px) {
  .offerAlert__inner {
    font-size: 0.8em;
  }
}



