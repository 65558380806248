.package_wrapper{
  padding: 1rem;
}
  
.wrapper.packacge_card_wrapper{
  margin: 0;
  padding: 1rem;
}

.heading_faqs {
  text-align: center;
  text-transform: capitalize;
  font-size: 2.5em;
  font-weight: 700;
  letter-spacing: 5;
  word-spacing: 5px;
  margin-bottom: 50px;
  color: #2c3148;
}
.main-div-faq {
  margin: 10px;
  width: 90%;
  margin-left: 70px;
}
.main-heading_package {
  width: 50%;
  display: flex;
  margin-top: -30px;
  padding: 20px 0 20px 20px;
  background: #f8f8ff;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}

.main-heading_package:hover {
  /* background-color: #d0e2f6; */
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}
.main-heading_package:first-child {
  margin-top: 0;
}
.content {
  display: none;
}
.main-heading_package h3 {
  margin-left: 30px;
  word-spacing: 1px;
  /* letter-spacing: 1px; */
  font-weight: 500;
  font-size: 20px;
}

.main-heading_package p {
  margin: 1rem;
}

.answers {
  padding: 19px;
  font-size: 14px;
  /* word-spacing: 2px; */
  font-weight: 400;
  line-height: 1.7;
  background: rgba(203, 224, 231, 0.959);
  color: black;
  /* text-transform: capitalize; */
}

.Show-hide-faq > i:before{
  font-size: 1.5rem;
}

.loadmore-faq{
  margin-top: 12px !important;
}

.search_heading.search_heading-packages{
  padding: 0;
}
@media screen and (max-width: 524px) and (min-width: 320px) {
  /* .main-div-faq {
    margin: 9px;
    margin-top: -21px;
  } */
  #load-more {
    margin-left: 55px;
  }
}
@media (max-width: 1024px) and (min-width: 320px) {
  .content h3 {
    padding: 0 !important;
  }

  .main-heading_package{
    width: 100%;
  }
  .main-heading_package h3 {
    margin-left: 0;
    margin: auto;
  }

  .faq_components {
    width: 80vw !important;
    margin: auto;
  }
  .answers {
    width: 80vw !important;
  }

  .main-div-faq{
    margin-left: 0;
    margin: auto;
  }
}
