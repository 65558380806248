.pLogin_header{
  background: #ffffff;
  color: #007bff;
  text-align: center;
  width: 80%;
  margin: 0 auto 10px;
  padding: 0.4rem 1rem;
  border-bottom: 2px solid #007bff;
}

.pLogin_header h3{
  margin: 0;
}

.u-form-group button {
  width: auto;
  padding: 0.5rem 3.5rem;
}

@media (max-width: 770px){
  .pLogin_header > h3{
    font-size: 21px;
  }
}