.userPassword-container label {
  font-size: 14px;
  color: #898989;
  margin: 0 0 0.5em 0;
}
.userPassword-container input {
  display: block;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 0.2em;
}

.userPassword-container {
  width: 100%;
  height: 42%;
  background: #fff;
  border-radius: 1em;
  box-shadow: 2px 2px 5px #ddd;
  padding: 1em 1.5em;
}

.userPassword__section1 {
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #ddd;
}

.userPassword__section2 {
  display: flex;
  justify-content: space-between;
}

.userPassword__section2 div {
  width: 48%;
}

.userPassword__section3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.userPassword__section3 div {
  width: 48%;
}

.UserPassword-button {
  width: 100%;
  height: 2.2em;
  border-radius: 5em;
  border: none;
  background-color: #007bff;
  color: white;
  transition: 0.1s ease-in;
  margin: 1em auto;
}

.UserPassword-button:hover {
  background-color: #0069d9;
}

@media (max-width: 645px) {
  .userPassword-container {
    width: 90%;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }

    
  .userPassword__section2,
  .userPassword__section3 {
    flex-direction: column;
  }
  
  .userPassword__section2 div,
  .userPassword__section3 div {
    width: 100%;
  }

}
