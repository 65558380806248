body {
  margin: 0;
  padding: 0;
  letter-spacing: -0.01em;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-family: "Manrope", sans-serif !important;
  }
  .image-section_tc{ 
    margin: 0px;
   width: 150px;
  }
  .image-section_tc img{
    width: 100px;
    height: auto;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
  
  .title_tc{
      font-size: 25px;
      text-align: left;
      padding-left: 50px;
  }
  .content_tc h3{
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    padding: 0px 50px 0px;
  }
  .content_tc p{
    font-size: 15px;
    font-weight: lighter;
    text-align: left;
    padding: 0px 50px 0px;
  } 
  .content-section_tc .social{
      margin: 40px 40px;
  }
  .content-section_tc .social i{
    color: #1E90FF;
    font-size: 30px;
    padding: 0px 10px;
}