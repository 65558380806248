.label_login {
  margin-left: 58px;
  font-size: 18px;
  display: table;
  margin-bottom: 10px;
}
.btn-group,
.btn-group-vertical {
  display: grid;
  position: relative;
  vertical-align: middle;
  width: 76%;
  margin-inline: auto; 
  border: 1px solid #ddd;
  grid-template-columns: 1fr 1fr;
}

.login-box .btn-group{
  display: grid;
}
.btn-group .btn {
  /* flex: 1 1 auto; */
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}

.btn--preferred {
  background-color: transparent;

  color: #007bff;
  transition: border 0.7s ease, color 0.7s ease, background 0.7s ease;
  font-size: 1.4rem;
}

.privacy_text {
  font-size: 13px;
  padding-bottom: 8px;
  width: 78%;
  margin: auto;
}
.foot-lnk {
  display: block;
  width: 102%;
}
.image_login {
  align-items: center;
  width: 18%;
  height: min-content;
  /* margin-left: 190px; */
  margin: 0 auto;
  display: block;
}
.login-box {
  position: relative;
  margin: 10px auto;
  width: 500px;
  /* height: 716px; */
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.33);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.33);
}

.email-login,
.email-signup {
  position: relative;
  /* float: left; */
  width: 100%;
  height: auto;
  margin-top: 20px;
  text-align: center;
}
.u-form-group {
  width: 163%;
  margin-bottom: 11px;
  align-items: center;
  margin-left: -150px;
}
.u-form-group input[type="email"],
.u-form-group input[type="text"],
.u-form-group input[type="password"] {
  width: calc(50% - 22px);
  height: 45px;
  outline: none;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 2px;
  color: #333;
  font-size: 0.8rem;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.u-form-group input:focus {
  border-color: #358efb;
}
.u-form-group button {
  width: 47%;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: normal;
  padding: 13px 0;
  border-radius: 2px;
  text-transform: uppercase;
  background: #007bff;
}
.u-form-group button:hover{
  background: #005bbc;
}
.forgot-password {
  width: 50%;
  text-align: left;
  text-decoration: underline;
  color: #888;
  font-size: 0.75rem;
}

@media (max-width: 770px) {
  /* .login-box{
    width: 102vw;
  } */

  .u-form-group {
    width: 100%;
    margin-left: 0;
  }

  .u-form-group input[type="email"],
  .u-form-group input[type="text"],
  .u-form-group input[type="password"] {
    /* width: 55vw; */
    width: 88%;
  }

  .label_login {
    /* margin-left: 0;
    display: inline-block; */
    display: flex;
    margin-left: 6%;
  }
}

@media (max-width: 500px) {
  .login-box {
    width: 95%;
  }

  /* .image_login {
    position: relative;
    left: 44%;
    margin-left: 0;
  } */

  .privacy_text{
    margin-left: 0;
    width: 100%;
  }
}
