.Requestbooking_css {
    position: relative;
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* max-width: 200vh;
  max-height: 200vh; */
    padding: 20px;
}

.form h1 {
    color: #fff;
    font-weight: 700;
}

.Requestbooking_css input {
    border-radius: 25px;
    padding: 20px;
    width: 70%;
    outline: none;
}

.Requestbooking_css:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(2, 0, 36);
    background: linear-gradient( 90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    transform: scaleX(1.5);
}

.Requestbooking_css .form {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 900px;
    text-align: center;
}

.Requestbooking_css .form p {
    color: #fff;
    font-size: 16px;
    position: relative;
    top: -30px;
}

input#cdate {
    border-radius: 25px;
    padding: 20px;
    width: 70%;
    outline: none;
}

.next_btn_requestbooking {
    background-color: #87ceeb;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
    padding: 15px 32px;
    margin: 30px;
}

@media (max-width: 767px) {
    .Requestbooking_css .form p {
        font-size: 12px;
    }
    .form h1 {
        color: #fff;
        font-weight: 700;
        font-size: 20px;
    }
    .Requestbooking_css {
        background: linear-gradient( 90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
    }
}