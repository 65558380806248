.modal_bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
}

.book_package_modal .close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 25px;
  cursor: pointer;
}

.modal-heading-container {
  background-color: #007bff;
  text-align: center;
  padding: 0.5rem;
  width: calc(100% - 44px);
  margin: auto;
}

.modal-heading {
  font-size: 22px;
  margin: 0;
  color: #fff;
}

.book_package-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem auto;
  width: calc(100% - 44px);
}

.input-group {
  display: flex;
}

.input-group > input,
.input-group > select {
  flex: 1;
  padding: 4px;
}

.input-group > input:nth-child(2),
.input-group > select:nth-child(2) {
  margin-left: 20px;
}

.book_package-submit_btn {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 32px;
  border-radius: 6px;
  background: #007bff;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: auto;
}
.book_package-submit_btn:hover {
  background: #005bbc;
  color: #fff;
}
@media (max-width: 770px) {
  /* .input-group {
    flex-direction: column;
  } */

  /* .input-group > input:nth-child(2),
  .input-group > select:nth-child(2) {
    margin: 0px;
  } */
}

@media (max-width: 500px) {
  .book_package_modal{
    height: 80vh;
    overflow: scroll;
  }
  .input-group {
    flex-direction: column;
    gap: 1rem;
  }

  .input-group > input:nth-child(2),
  .input-group > select:nth-child(2) {
    margin: 0px;
  }
}
