.heading_faqs {
  text-align: center;
  text-transform: capitalize;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 5;
  word-spacing: 5px;
  margin-bottom: 50px;
  color: #2c3148;
  margin-top: 20px;
}


@media (max-width: 540px) {
  .heading_faqs {
    font-size: 24px;
    font-weight: 600;
  }
}

.main-div-faq {
  margin: 10px;
  width: 90%;
  margin-left: 70px;
}
.main-heading_faq2 {
  display: flex;
  margin-top: -30px;
  padding: 20px 0 20px 20px;
  background: #f8f8ff;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}

.main-heading_faq2:hover {
  background-color: #d0e2f6;
  cursor: pointer;
}
.main-heading_faq2:first-child {
  margin-top: 0;
}
.content {
  display: none;
}
.main-heading_faq2 h3 {
  margin-left: 30px;
  word-spacing: 1px;
  /* letter-spacing: 1px; */
  font-weight: 500;
  font-size: 18px;
}

.main-heading_faq2 p {
  width: 3vh;
  cursor: pointer;
  padding: 5px;
}

.answers {
  padding: 19px;
  font-size: 18px;
  /* word-spacing: 2px; */
  padding-left: 50px;
  font-weight: 400;
  line-height: 1.7;
  background: rgba(203, 224, 231, 0.959);
  color: black;
  /* text-transform: capitalize; */
}
.Show-hide-faq {
  font-size: 800;
}

.loadmore-faq {
  margin-top: 12px !important;
}

@media screen and (max-width: 524px) and (min-width: 320px) {
  /* .main-div-faq {
    margin: 9px;
    margin-top: -21px;
  } */
  #load-more {
    margin-left: 55px;
  }
}
@media (max-width: 767px) and (min-width: 320px) {
  .content h3 {
    padding: 0 !important;
  }

  .main-heading_faq2 h3 {
    margin-left: 0;
    margin: auto;
  }

  .faq_components {
    width: 80vw !important;
    margin: auto;
  }
  .answers {
    width: 80vw !important;
    padding-left: 20px;
  }

  .main-div-faq {
    margin-left: 0;
    margin: auto;
  }
}